import { connect } from 'react-redux';

import { ComponentInfo } from 'views/components/ComponentInfo/ComponentInfo';
import { IState } from 'redux/modules';
import { shouldShowMeta } from 'redux/modules/debug/selectors';

const mapStateToProps = (state: IState) => ({
  shouldShowMeta: shouldShowMeta(state),
});

export const ComponentInfoConnected = connect(
  mapStateToProps,
  null,
)(ComponentInfo);
