import IcoMoonIcons from '../../../tools/utilities/icomoon-content';
import SVGs from '../../../tools/utilities/svg-content';

const ENVIRONMENTS = {
  DEV: 'dev',
  PRE_PROD: 'pre-prod',
  PROD: 'production',
};

export const {
  NODE_ENV,
  REACT_APP_ENVIRONMENT,
  REACT_APP_FEATURE_BRANCH,
  REACT_APP_COMMIT_HASH,
  REACT_APP_CMS_ACCESS_TOKEN,
  REACT_APP_CMS_SPACE,
  REACT_APP_CMS_ENV,
  REACT_APP_CMS_HOST,
  REACT_APP_VERSION,
  REACT_APP_MAILCHIMP_AUDIENCE_ID,
  REACT_APP_MAILCHIMP_SERVER_LOC,
  REACT_APP_MAILCHIMP_ID,
  REACT_APP_GA_TOKEN,
} = process.env;

export const ENVIRONMENT = REACT_APP_ENVIRONMENT || NODE_ENV;
export const APP_VERSION = REACT_APP_VERSION;

// export const FEATURE_BRANCH = REACT_APP_FEATURE_BRANCH;
// export const COMMIT_HASH = REACT_APP_COMMIT_HASH;

export const IS_DEVELOPMENT = REACT_APP_ENVIRONMENT === ENVIRONMENTS.DEV;
export const IS_PRE_PRODUCTION = REACT_APP_ENVIRONMENT === ENVIRONMENTS.PRE_PROD;
export const IS_PRODUCTION = REACT_APP_ENVIRONMENT === ENVIRONMENTS.PROD;

export const CMS_ACCESS_TOKEN = REACT_APP_CMS_ACCESS_TOKEN;
export const CMS_SPACE = REACT_APP_CMS_SPACE;
export const CMS_ENV = REACT_APP_CMS_ENV;
export const CMS_HOST = REACT_APP_CMS_HOST; // Only present in development

export const MAILCHIMP_AUDIENCE_ID = REACT_APP_MAILCHIMP_AUDIENCE_ID;
export const MAILCHIMP_SERVER_LOC = REACT_APP_MAILCHIMP_SERVER_LOC;
export const MAILCHIMP_ID = REACT_APP_MAILCHIMP_ID;

export const GA_TOKEN = REACT_APP_GA_TOKEN;

// Misc
export const ICONS = {
  IcoMoonIcons,
  SVGs,
};

export const APP_DELAYS = {
  MENU_CLOSE: 300,
};

export const KEY_CODES = {
  ESCAPE: 27,
  ENTER: 16,
};

export const USER_ROLES = {
  USER: 'user',
};

export default {
  APP_DELAYS,
  KEY_CODES,
};

export const OVERSEER_URLS = {
  PORTAL_SIGN_UP: 'https://fm.overseer.org.nz/#/pub/register',
  PORTAL_LOG_IN: 'https://fm.overseer.org.nz/#/pub/login',
  TWITTER: 'https://twitter.com/OVERSEERLimited?lang=en',
  LINKEDIN: 'https://www.linkedin.com/company/overseer-limited',
  YOUTUBE: 'https://www.youtube.com/channel/UCW4gyc1kSYSRqv9iNi-y3ig',
  FACEBOOK: 'https://www.facebook.com/overseer.org.nz',
};

export const PRIMARY_NAVIGATION = {
  PRIMARY: [
    {
      name: 'OVERSEERFM',
      url: '/overseerfm',
    },
    {
      name: 'SUPPORT_AND_TRAINING',
      url: '/support-and-training',
    },
    {
      name: 'OUR_SCIENCE',
      url: '/our-science',
    },
    {
      name: 'OUR_MODEL',
      url: '/our-model',
    },
    /*
    {
      name: 'REPORTS',
      url: '/reports',
    },
    */
    {
      name: 'OUR_STORY',
      url: '/our-story',
    },
    {
      name: 'TIMELINE',
      url: '/page-timeline',
      hideInHeader: true,
    },
    {
      name: 'OUR_NEWS',
      url: '/our-news',
    },
    {
      name: 'CONTACT',
      url: '/page-contact',
    },
  ],
};

export const GROUPED_NAVIGATION = {
  PRODUCT: [
    {
      name: 'ABOUT_OVERSEERFM',
      url: '/overseerfm',
      isRoute: true,
    },
    {
      name: 'PRICING',
      url: '/overseerfm#pricing',
      isRoute: true,
    },
    {
      name: 'SUPPORT_AND_TRAINING',
      url: '/support-and-training',
      isRoute: true,
    },
    {
      name: 'TERMS_OF_USE',
      url: 'https://docs.overseer.org.nz/fm/OverseerTermsOfUse.pdf',
      shouldOpenNewTab: true,
    },
    {
      name: 'PRIVACY_POLICY',
      url: 'https://docs.overseer.org.nz/fm/OverseerPrivacyPolicy.pdf',
      shouldOpenNewTab: true,
    },
  ],
  COMPANY: [
    {
      name: 'OUR_STORY',
      url: '/our-story',
      isRoute: true,
    },
    {
      name: 'OUR_SCIENCE',
      url: '/our-science',
      isRoute: true,
    },
    {
      name: 'OUR_MODEL',
      url: '/our-model',
      isRoute: true,
    },
    {
      name: 'NEWSLETTERS',
      url: 'https://us17.campaign-archive.com/home/?u=2ad5226723d807fe286685ba9&id=0279058b7f',
      shouldOpenNewTab: true,
    },
    {
      name: 'OUR_NEWS',
      url: '/our-news',
      isRoute: true,
    },
    {
      name: 'CONTACT',
      url: '/page-contact',
      isRoute: true,
    },
  ],
};
