import React, { useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { AkuakuContext } from 'contexts/AkuakuContext';
import { useSelector } from 'react-redux';
import logo from 'images/blue-coloured-no-background@2x.png';

import { debounceAnimation } from 'tools/utilities/debounceAnimation';

import { PrimaryMenu } from 'views/components/PrimaryMenu/PrimaryMenu';
import { UtilityMenu } from 'views/components/UtilityMenu/UtilityMenu';

const GlobalHeader = (props) => {
  const isHidden = useSelector(state => state.app.isGlobalHeaderHidden);

  let headerElement;
  const [context,] = useContext(AkuakuContext);


  useEffect(() => {
    const storeScroll = () => {
      if (headerElement) {
        headerElement.dataset.scroll = window.scrollY;
      }
    };

    document.addEventListener('scroll', debounceAnimation(storeScroll));

    return () => {
      document.removeEventListener('scroll', debounceAnimation(storeScroll));
      document.addEventListener('scroll', debounceAnimation(storeScroll));
    };
  });

  if (isHidden || (context && context.hideHeader )) {
    return null;
  }

  return (
    <header
      className="GlobalHeader u-print-hidden"
      ref={(node) => {
        headerElement = node;
      }}
    >
      <h1 className="u-hiddenVisually">
        <FormattedMessage id="APP_TITLE" />
      </h1>

      <div className="Container">
        <div className="GlobalHeader-wrapper Grid">
          <div className="GlobalHeader-menu">
            <h2 className="GlobalHeader-logo">
              <Link to="/">
                <img src={logo} alt="Overseer logo" />

                <span className="u-hiddenVisually">
                  <FormattedMessage id="HOME" />
                </span>
              </Link>
            </h2>

            <PrimaryMenu {...props} />
          </div>

          <UtilityMenu {...props} />
        </div>
      </div>
    </header>
  );
};

const GlobalHeaderWithRouter = withRouter(GlobalHeader);

export { GlobalHeaderWithRouter as GlobalHeader };
