import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { PRIMARY_NAVIGATION } from '../../../redux/modules/app/constants';

import { LargieSmalls } from '../LargieSmalls/LargieSmalls';
import { UtilityMenu } from '../UtilityMenu/UtilityMenu';
import { NavList } from '../NavList/NavList';

export const PrimaryMenu = ({ history }) => {
  const [isOpen, toggleMenu] = useState(false);

  const menuClassName = classnames('PrimaryMenu PrimaryMenu--sitewide', { 'is-open': isOpen });
  const navClassName = classnames('PrimaryMenu-wrapper', {
    'u-hiddenVisually': !isOpen,
  });

  const loadRoute = (route) => {
    if (isOpen) {
      toggleMenu();
    }

    return history.push(route);
  };

  return (
    <div className={menuClassName}>
      <h2 className="u-hiddenVisually">
        <FormattedMessage id="MENU" />
      </h2>

      <button className="Hamburger" type="button" onClick={() => toggleMenu(!isOpen)}>
        <span />
        <span />
        <span />
        <span />
      </button>

      <LargieSmalls
        small={(
          <div className={navClassName}>
            <div className="Container">
              <div className="PrimaryMenu-nav">
                <NavList onClickLink={route => loadRoute(route)} />

                <UtilityMenu />
              </div>
            </div>
          </div>
)}
        large={(
          <NavList
            items={PRIMARY_NAVIGATION}
            shouldShowHeaders={false}
            onClickLink={route => loadRoute(route)}
          />
)}
      />
    </div>
  );
};

PrimaryMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
