import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import posed, { PoseGroup } from 'react-pose';
import classNames from 'classnames';
import { AccessibleLoadNotifier } from 'views/components/AccessibleLoadNotifier/AccessibleLoadNotifier';
import { RouteComponentProps } from 'react-router-dom';
import { AVAILABLE_MODULES } from 'views/components/RenderModules/RenderModules';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    page: 'News Summary',
  },
  dataLayerName: 'PageDataLayer',
};

const Container = posed.div({
  enter: {
    opacity: 1,
    transition: { delay: 200, duration: 300 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 200 },
  },
});

interface IModule {
  contentType: keyof typeof AVAILABLE_MODULES;
  id: string;
}

type TParams = {cmsPageId:string}

interface IProps extends RouteComponentProps<TParams> {
  page: {
    metaTitle: string;
    metaDescription: string;
    modules: IModule[];
  };
  cmsPageId: string;
  getPage: (id: string) => void;
  component: any;
  isPageLoading: boolean;
  isViewportSmall: boolean;
  defaultPageId : string;
}

export class NewsSummaryPage extends React.Component<IProps> {
  componentDidMount() {
    const { getPage } = this.props;
    getPage('our-news');
    TagManager.dataLayer(tagManagerArgs);
  }

  render() {
    const { isPageLoading, page } = this.props;
    const { modules } = page;

    return (
      <main className={classNames('Main', { [`Main--our-news`]: 'our-news' })}>

        <Fragment>
          <MetaTags>
            <title>Overseer News</title>
            <meta name="description" content="Latest news from Overseer" />
            <meta property="og:title" content="Overseer news" />
          </MetaTags>

          <AccessibleLoadNotifier isLoading={isPageLoading} />

          <PoseGroup>
            {!isPageLoading && (
              <Container key="our-news">
                <div className="Page">
                  <section>
                    <div className="Container">
                      <div className="u-flex u-flexWrap u-flexJustifyBetween u-mT-sm u-mB-lg">
                        {
                          modules && modules.map((module, i) => {
                            const { contentType } = module;
                            if (!AVAILABLE_MODULES[contentType]) return null;
                            
                            const viewModule = [AVAILABLE_MODULES[contentType](module)];

                            return <div className={contentType === 'moduleNewsArticle' ? 'NewsArticle' : 'NewsArticle-ext'} key={i}>{viewModule}</div>;
                          })
                        }
                      </div>
                    </div>
                  </section>
                </div>
                <div className="Background Background--top" aria-hidden />
                <div className="Background Background--middle" aria-hidden />
                <div className="Background Background--bottom" aria-hidden />
              </Container>
            )}
          </PoseGroup>
        </Fragment>
      </main>
    );
  }
}
