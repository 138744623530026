export default [
  // ===========
  // Farm inputs
  // ===========
  { source: 'structuresEffluentSystemsApplications', target: 'effluentManagement' },
  { source: 'structuresEffluentSystemsApplications', target: 'animalIntakes' },

  { source: 'animalsInput', target: 'animalsComponent' },

  { source: 'supplementsInput', target: 'supplementsComponent' },

  { source: 'animalDistribution', target: 'interblock' },

  { source: 'pastureInput', target: 'supplementsComponent' },
  { source: 'pastureInput', target: 'pastureComponent' },

  { source: 'fertiliserInput', target: 'pastureComponent' },
  { source: 'fertiliserInput', target: 'blockN' },

  { source: 'fruitInput', target: 'fruitComponent' },

  { source: 'cropsInput', target: 'cropsComponent' },

  { source: 'climateInput', target: 'climateComponent' },

  { source: 'irrigationInput', target: 'irrigationComponent' },

  { source: 'soilInput', target: 'soilComponent' },

  { source: 'soilTestsInput', target: 'soilComponent' },

  { source: 'drainageInput', target: 'soilComponent' },
  { source: 'drainageInput', target: 'wetlands' },

  // =====================
  // Models and components
  // =====================

  // Column 1 links
  { source: 'animalsComponent', target: 'animalIntakes' },

  { source: 'supplementsComponent', target: 'animalIntakes' },

  { source: 'pastureComponent', target: 'supplementsComponent' },
  { source: 'pastureComponent', target: 'animalIntakes' },
  { source: 'pastureComponent', target: 'blockN' },

  { source: 'fruitComponent', target: 'blockN' },
  { source: 'fruitComponent', target: 'hydrology' },

  { source: 'cropsComponent', target: 'blockN' },
  { source: 'cropsComponent', target: 'hydrology' },

  { source: 'climateComponent', target: 'cropsComponent' },
  { source: 'climateComponent', target: 'blockN' },
  { source: 'climateComponent', target: 'hydrology' },
  { source: 'climateComponent', target: 'urinePatch' },
  { source: 'climateComponent', target: 'wetlands' },

  { source: 'irrigationComponent', target: 'hydrology' },

  { source: 'soilComponent', target: 'hydrology' },
  { source: 'soilComponent', target: 'urinePatch' },

  // Column 2 links
  { source: 'effluentManagement', target: 'effluentReports' },
  { source: 'effluentManagement', target: 'interblock' },

  { source: 'animalIntakes', target: 'animalReports' },
  { source: 'animalIntakes', target: 'effluentManagement' },
  { source: 'animalIntakes', target: 'interblock' },
  { source: 'animalIntakes', target: 'methaneEmissions' },
  { source: 'animalIntakes', target: 'nitrousOxide' },

  { source: 'interblock', target: 'animalReports' },
  { source: 'interblock', target: 'urinePatch' },
  { source: 'interblock', target: 'nitrousOxide' },

  { source: 'blockN', target: 'nitrousOxide' },
  { source: 'blockN', target: 'nPoolsGraphs' },

  { source: 'hydrology', target: 'blockN' },
  { source: 'hydrology', target: 'wetlands' },
  { source: 'hydrology', target: 'urinePatch' },

  { source: 'wetlands', target: 'nutrientBudgets' },
  { source: 'wetlands', target: 'wetlandBudgets' },

  // Column 3 links
  { source: 'methaneEmissions', target: 'ghgReports' },

  { source: 'nitrousOxide', target: 'ghgReports' },

  { source: 'urinePatch', target: 'nitrousOxide' },
  { source: 'urinePatch', target: 'nutrientBudgets' },

  // =====================
  // OVERSEER RESULTS
  // =====================
  { source: 'nutrientBudgets', target: 'nPoolsGraphs' },
];
