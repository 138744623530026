import React from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { GROUPED_NAVIGATION } from '../../../redux/modules/app/constants';

import { Button } from '../Button/Button';

const NavList = (props) => {
  const {
    intl, location, onClickLink, shouldShowHeaders, items, history,
  } = props;

  const handleOnClick = (route) => {
    if (onClickLink) {
      return onClickLink(route);
    }

    return history.push(route);
  };

  if (!items) {
    return null;
  }

  return (
    <nav className="NavList">
      {Object.keys(items).map(section => (
        <div key={section} className="NavList-section">
          {shouldShowHeaders && (
            <span className="NavList-subHeading u-subHeading">
              <FormattedMessage id={section} />
            </span>
          )}

          <ul className="NavList-list">
            {items[section].filter(page => !page.hideInHeader).map(page => (
              <li
                key={`NavList-item-${page.name}`}
                className={classnames('NavList-item', {
                  'is-active': location.pathname === page.url,
                })}
              >
                <Button
                  {...page}
                  className="NavList-link"
                  onClick={() => handleOnClick(page.url)}
                  label={intl.formatMessage({ id: page.name })}
                />
              </li>
            ))}
          </ul>

          <Button
            aria-hidden
            className="u-hidden"
            label={intl.formatMessage({ id: 'PREVIEW' })}
            onClick={() => handleOnClick('/preview')}
            buttonType="button"
          />
        </div>
      ))}
    </nav>
  );
};

NavList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  items: PropTypes.shape({}),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  onClickLink: PropTypes.func,
  shouldShowHeaders: PropTypes.bool,
};

NavList.defaultProps = {
  items: GROUPED_NAVIGATION,
  onClickLink: null,
  shouldShowHeaders: true,
};

const NavListWithIntl = withRouter(injectIntl(NavList));

export { NavListWithIntl as NavList };
