import React from 'react';
import classNames from 'classnames';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { IImage } from 'types';
import { Button } from 'views/components/Button/Button';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';
import { RichText } from 'views/components/RichText/RichText';
import { Document } from '@contentful/rich-text-types';
import { IS_DEVELOPMENT } from 'redux/modules/app/constants';

const SPLIT_VARIATION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

interface IProps {
  title: string;
  richText: Document;
  image: IImage;
  variation?: keyof typeof SPLIT_VARIATION;
  link?: string;
  linkLabel?: string;
  idSlug?: string;
  
}

export const Split = (props: IProps) => {
  const {
    title,
    richText,
    image,
    variation,
    link,
    linkLabel,
    idSlug,
  } = props;

  // Variation is based on the text position
  let isLeftVariation = false; // Text on the left, image on the right
  let isRightVariation = false; // Text on the right, image on the left
  let zeplinId = 'SPLIT01-01a';
  const shouldOpenNewTab = link && link.includes('http')
  const { file = { url: '' } } = image;

  switch (variation) {
    case SPLIT_VARIATION.RIGHT:
      isRightVariation = true;
      zeplinId = 'SPLIT01-01b';
      break;
    case SPLIT_VARIATION.LEFT:
    default:
      isLeftVariation = true;
      break;
  }

  if (link && linkLabel) {
    zeplinId = ' SPLIT01-01c';
  }

  const splitClasses = classNames('Split ModuleWrapper', {
    'Split--left': isLeftVariation,
    'Split--right': isRightVariation,
  });

  const textContent = (
    <div className="Grid-cell u-width11of12 u-md-width5of12">
      <h3 className="Split-header h2">{title}</h3>
      <div className="Split-richText">
        <RichText text={richText} />
      </div>

      {link && linkLabel && (
        <Button
          className="Link u-block u-linkClean u-mT-xs"
          isLinkButton
          label={linkLabel}
          url={link}
          shouldOpenNewTab={shouldOpenNewTab}
        />
      )}
    </div>
  );

  const imageContainerClasses = classNames(
    'Split-imageContainer Grid-cell u-width1of1 u-md-width1of2',
    {
      'u-md-before1of12': isLeftVariation,
      'u-md-after1of12': isRightVariation,
    },
  );

  return (
    <section id={idSlug} className={splitClasses}>
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false} />

      <div className="Container">
        <div className="Split-content Grid">
          {isLeftVariation && textContent}

          <div className={imageContainerClasses}>
            {file.url.length > 0 ?
              <CmsImageConnected
                mediaType="large"
                className="Split-image"
                src={file.url}
                alt={image.description || ''}
                fit={{
                  small: { w: 268, h: 181 },
                  medium: { w: 331, h: 181 },
                  large: { w: 440, h: 241 },
                  xxLarge: { w: 540, h: 295 },
                }}
              />:
              <>{IS_DEVELOPMENT ? <h2>Image url missing</h2> : null}</>
            }
          </div>

          {isRightVariation && textContent}
        </div>
      </div>
    </section>
  );
};
