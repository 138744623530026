import { connect } from 'react-redux';

import { IState } from 'redux/modules';
import { mediaType } from 'redux/modules/app/selectors';
import { CmsImage } from 'views/components/CmsImage/CmsImage';

const mapStateToProps = (state: IState) => ({
  mediaType: mediaType(state),
});

export const CmsImageConnected = connect(
  mapStateToProps,
  null,
)(CmsImage);
