import types from './types';

export const initialState = {
  isGlobalHeaderHidden: false,
  isGlobalFooterHidden: false,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_GLOBAL_HEADER:
      return {
        ...state,
        isGlobalHeaderHidden: false,
      };
    case types.HIDE_GLOBAL_HEADER:
      return {
        ...state,
        isGlobalHeaderHidden: true,
      };
    case types.SHOW_GLOBAL_FOOTER:
      return {
        ...state,
        isGlobalFooterHidden: false,
      };
    case types.HIDE_GLOBAL_FOOTER:
      return {
        ...state,
        isGlobalFooterHidden: true,
      };
    default:
      return state;
  }
};
