import { IState } from 'redux/modules';
import { ICmsData } from 'redux/modules/cms/reducers';

// @TODO See if we can update the CMS saga to only return 1 entry instead of an array
export const page = (state: IState) => {
  const {
    cmsReducer: {
      page,
      page: {
        currentPage,
      },
    },
  } = state;

  // @ts-ignore
  if (!currentPage || !(page[currentPage] as ICmsData)) {
    return {};
  }

  // Return first entry in the data array as we're only expecting a single
  // page to be returned
  // @ts-ignore
  return (page[currentPage] as ICmsData).data[0] || {};
};

export const isPageLoading = (state: IState) => {
  const {
    cmsReducer: {
      page: {
        isLoading,
      },
    },
  } = state;

  return isLoading;
};

export const isScientificModelItemLoading = (state: IState) => {
  const {
    cmsReducer: {
      scientificModel: {
        isLoading,
      },
    },
  } = state;

  return isLoading;
};

export const getScientificModelCurrentItem = (state: IState) => {
  const {
    cmsReducer: {
      scientificModel: {
        currentItem,
      },
    },
  } = state;

  if (currentItem === null) {
    return {};
  }

  return currentItem.data[0];
};

export const getScientificModelCache = (state: IState) => state.cmsReducer.scientificModel.cachedItems;
