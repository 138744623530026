import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import scrollIntoView from 'scroll-into-view';
import posed, { PoseGroup } from 'react-pose';

import { AccessibleLoadNotifier } from 'views/components/AccessibleLoadNotifier/AccessibleLoadNotifier';

const BASE_TITLE = 'Overseer';
// These variables need to match what's in the layout-types.scss file.
const HEADER_HEIGHT_SMALL = 48; // $header-max-height-sm: 48px;
const HEADER_HEIGHT_LARGE = 80; // $header-max-height-lg: 80px;

interface IProps {
  page: {
    metaTitle: string;
    metaDescription: string;
  };
  cmsPageId: string;
  getPage: (id: string) => void;
  component: any;
  isPageLoading: boolean;
  isViewportSmall: boolean;
}

const Container = posed.div({
  enter: {
    opacity: 1,
    transition: { delay: 200, duration: 300 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 200 },
  },
});

export class CmsPage extends React.Component<IProps> {
  isScrolling: boolean = false;

  componentDidMount() {
    const { getPage, cmsPageId } = this.props;
    getPage(cmsPageId);
  }

  // This will only update on page changes
  componentDidUpdate() {
    const { isPageLoading, isViewportSmall } = this.props;
    const hashParam = window.location.hash;

    if (isPageLoading === false && !!hashParam && !this.isScrolling) {
      // Only the following modules are set up with an id on the module to use for scrolling into view
      // - CopyBlock
      // - Split
      // - FeatureList
      // - Videos
      // - Resources

      const elementToScrollTo = document.getElementById(hashParam);

      if (elementToScrollTo && !this.isScrolling) {
        this.isScrolling = true;

        scrollIntoView(
          elementToScrollTo,
          {
            align: {
              topOffset: (isViewportSmall ? HEADER_HEIGHT_SMALL : HEADER_HEIGHT_LARGE) * 2,
            },
          },
          () => {
            this.isScrolling = false;
          },
        );
      }
    }
  }

  render() {
    const {
      page,
      component: ComponentToRender,
      page: { metaTitle, metaDescription },
      isPageLoading,
      cmsPageId,
    } = this.props;

    // Fallback to Overseer if no title is present
    const fullTitle = metaTitle || BASE_TITLE;

    return (
      <Fragment>
        <MetaTags>
          <title>{fullTitle}</title>
          <meta name="description" content={metaDescription || ''} />
          <meta property="og:title" content={fullTitle} />
        </MetaTags>

        <AccessibleLoadNotifier isLoading={isPageLoading} />

        <PoseGroup>
          {!isPageLoading && (
            <Container key={cmsPageId}>
              <div className="Page">
                <ComponentToRender {...page} />
              </div>

              <div className="Background Background--top" aria-hidden />
              <div className="Background Background--middle" aria-hidden />
              <div className="Background Background--bottom" aria-hidden />
            </Container>
          )}
        </PoseGroup>
      </Fragment>
    );
  }
}
