import React from 'react';
import { Document } from '@contentful/rich-text-types';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Icon } from 'views/components/Icon/Icon';
import { Button } from 'views/components/Button/Button';
import { RichText } from 'views/components/RichText/RichText';

interface IListItem {
  title: string;
  text?: string;
  iconName: string;
  id: string;
  richText?: Document;
}

interface IProps {
  title: string;
  text?: string;
  items: IListItem[];
  idSlug?: string;
  buttonLabel?: string;
  buttonLink?: string;
}

export const FeatureList = (props: IProps) => {
  const {
    title,
    text,
    items,
    idSlug,
    buttonLabel,
    buttonLink,
  } = props;

  const hasButton = buttonLabel && buttonLink;

  return (
    <section id={idSlug} className="FeatureList ModuleWrapper">
      <ComponentInfoConnected zeplinId={`FEATURELIST01-01${hasButton ? 'b' : 'a'}`} shouldShowMeta={false}/>

      <div className="Container">
        <div className="Feature-list-content">
          <h2 className="h1 Feature-list-title">{title}</h2>
          {text && <p className="u-mT-0 u-mB-0">{text}</p>}
          {hasButton && (
            <Button
              className="Button Button--secondary Button--hasShadow"
              isLinkButton
              label={buttonLabel}
              url={buttonLink}
            />
          )}
        </div>

        <ul className="Grid FeatureList-list">
          {items.map(({
            title: itemTitle, text: itemText, iconName, id, richText,
          }) => (
            <li key={id} className="Feature-list-gridItem Grid-cell u-width1of1 u-md-width1of3 u-lg-width1of4">
              <div className="FeatureList-item">
                <div className="FeatureList-item-icon" aria-hidden>
                  <Icon name={iconName} className="SVG" />
                </div>

                <h3 className="u-textMedium u-mB-0 u-mT-0">{itemTitle}</h3>

                {itemText && !richText && (
                  <p className="u-mB-0 u-mT-xs u-textPreWrap">{itemText}</p>
                )}

                {richText && (
                  <div className="FeatureList-item-richText u-mT-xs u-textPreWrap">
                    <RichText text={richText} />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>

      </div>
    </section>
  );
};
