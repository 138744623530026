import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { FilterList } from 'views/components/FilterList/FilterList';

interface IResource {
  buttonLabel?: string;
  estimatedTime?: string;
  buttonLink?: string;
  tags: string[];
  text: string;
  title: string;
}

interface IProps {
  items: IResource[];
}

function WithFilter(WrappedComponent: any) {
  const WithFilterWrapped = ({ items, ...props }: IProps) => {
    const [filterList, setFilters] = useState<string[]>([]);

    const handleOnChangeFilters = (event: any) => {
      const { value } = event.currentTarget;

      if (filterList.includes(value)) {
        return setFilters(filterList.filter(filterItem => filterItem !== value));
      }

      return setFilters([...filterList, value]);
    };

    const filteredItems = items.filter(
      item => item.tags && item.tags.some(tag => filterList.indexOf(tag) !== -1),
    );

    const getItemsToDisplay = () => {
      if (filterList.includes('All')) {
        return items;
      }

      return filterList.length > 0 && filteredItems ? filteredItems : items;
    };

    const itemsToDisplay = getItemsToDisplay();

    const getAllFilters = () => {
      let filters = ['All'];

      if (items) {
        items.map((item) => {
          if (!item.tags) {
            return null;
          }

          return item.tags.filter((tag) => {
            if (filters.indexOf(tag) === -1) {
              filters = [...filters, tag];
            }

            return filters;
          });
        });
      }

      return filters;
    };

    return (
      <Fragment>
        <FilterList
          onChangeFilters={handleOnChangeFilters}
          selectedFilters={filterList}
          filters={getAllFilters()}
          {...props}
        />

        <WrappedComponent filterList={filterList} itemsToDisplay={itemsToDisplay} {...props} />
      </Fragment>
    );
  };

  WithFilterWrapped.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  return WithFilterWrapped;
}

const WithFilterWrapped = (WrappedComponent: React.ReactNode) => WithFilter(WrappedComponent);

export { WithFilterWrapped as WithFilter };
