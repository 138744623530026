import { takeEvery, put } from 'redux-saga/effects';
import * as contentful from 'contentful';

import { CMS, IGetPage, IGetScientificModelItem } from 'redux/modules/cms/actions';
import { formatEntries } from 'redux/modules/cms/utilities';
import {
  CMS_ACCESS_TOKEN,
  CMS_SPACE,
  CMS_ENV,
  CMS_HOST,
  IS_DEVELOPMENT,
} from 'redux/modules/app/constants';

// Host is needed to see draft content
let host = {};
if (CMS_HOST && IS_DEVELOPMENT) {
  host = {
    host: CMS_HOST,
  };
}

const client = contentful.createClient({
  space: CMS_SPACE as string,
  accessToken: CMS_ACCESS_TOKEN as string,
  environment: CMS_ENV as string,
  
  // @NOTE: Might not need this
  // resolveLinks: true, // A CMS Property to resolve links in nested entries.

  ...host,
});

function* getContentFromCMS(contentType: string, actionType: string, filter: any, actionPayload: any) {
  try {
    const response = yield client.getEntries({
      content_type: contentType,
      ...filter,
      include: 3, // The number of nested CMS entries to include
    });

    const formattedData = formatEntries(response);

    yield put({
      type: `${actionType}_SUCCESS`,
      payload: {
        ...actionPayload,
        data: formattedData,
        includes: response.includes,
      },
    });
  } catch (error) {
    console.error('CMS SAGA ERROR: ', error);
    yield put({ type: `${actionType}_FAIL`, payload: { error } });
  }
}

const getPage = (action: IGetPage) => {
  const { id } = action.payload;

  return getContentFromCMS('page', CMS.GET_PAGE, { 'fields.pageName': id }, { id });
};

const getScientificModelItem = (action: IGetScientificModelItem) => {
  const { id } = action.payload;

  return getContentFromCMS('scientificModelItem', CMS.GET_SCIENTIFIC_MODEL_ITEM, { 'sys.id': id }, { id });
};

export function* cmsSaga() {
  yield takeEvery(CMS.GET_PAGE, getPage);
  yield takeEvery(CMS.GET_SCIENTIFIC_MODEL_ITEM, getScientificModelItem);
}
