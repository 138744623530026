import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import posed from 'react-pose';
import { trackEvent } from 'tools/analytics';
import { Document } from '@contentful/rich-text-types';
import { RichText } from 'views/components/RichText/RichText';

export interface IFaq {
  title: string; // Question
  text: string; // Answer
  id: string;
  richText?: Document; // Optional richText answer that can include links and lengthy text
}

const Body = posed.div({
  closed: {
    height: 0,
  },
  open: {
    height: 'auto',
  },
});

export const FAQItem = (props: IFaq) => {
  const [isOpen, toggleOpen] = useState(false);

  const {
    title,
    text,
    id,
    richText,
  } = props;

  useEffect(() => {
    if (isOpen) {
      trackEvent('FAQ Opened', title);
    }
  }, [isOpen, title]);

  const tabId = `tab-${id}`;
  const panelId = `panel-${id}`;

  return (
    <div className={classNames('FAQItem', { 'is-open': isOpen })}>
      <button
        type="button"
        aria-expanded={isOpen}
        className="FAQItem-header"
        onClick={() => toggleOpen(!isOpen)}
        id={tabId}
        aria-controls={panelId}
        role="tab"
      >
        <h3 className="p u-mT-0 u-mB-0">
          {title}
        </h3>
      </button>

      <Body
        id={panelId}
        aria-labelledby={panelId}
        className="FAQItem-body"
        pose={isOpen ? 'open' : 'closed'}
        tabindex="-1"
        role="tabpanel"
      >
        {/* Display either RichText or a the standard text in a p tag */}
        {richText ? (
          <div className="FAQItem-body-richText">
            <RichText text={richText} />
          </div>
        ) : (
          <p className="FAQItem-body-text u-textPreWrap">{text}</p>
        )}
      </Body>
    </div>
  );
};
