export const debounceAnimation = (callback) => {
  let animationFrame;

  return (...params) => {
    if (animationFrame) {
      cancelAnimationFrame(animationFrame);
    }

    animationFrame = requestAnimationFrame(() => {
      callback(...params);
    });
  };
};
