import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import posed, { PoseGroup } from 'react-pose';
import classNames from 'classnames';
import { AccessibleLoadNotifier } from 'views/components/AccessibleLoadNotifier/AccessibleLoadNotifier';
import { RouteComponentProps } from 'react-router-dom';
import { IImage } from 'types';
import { RichText } from 'views/components/RichText/RichText';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';
import { Icon } from 'views/components/Icon/Icon';
import { Button } from '../Button/Button';
import Script from 'react-load-script'
import TagManager from 'react-gtm-module';
const { richTextFromMarkdown } = require('@contentful/rich-text-from-markdown');

const tagManagerArgs = {
  dataLayer: {
    page: 'News Article',
  },
  dataLayerName: 'PageDataLayer',
};


const Container = posed.div({
  enter: {
    opacity: 1,
    transition: { delay: 200, duration: 300 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 200 },
  },
});


interface IModule {
  contentType: string;
  id: string;
  slug: string;
  title: string
  body: string;
  heroImage: IImage;
  description: string;
  publishDate: Date;
  timeToRead: string;
}

type TParams = {cmsPageId:string}

interface IProps extends RouteComponentProps<TParams> {
  page: {
    metaTitle: string;
    metaDescription: string;
    modules: IModule[];
  };
  cmsPageId: string;
  getPage: (id: string) => void;
  component: any;
  isPageLoading: boolean;
  isViewportSmall: boolean;
  defaultPageId : string;
}

interface IState {
  body?: any,
  newsItem?: IModule,
  notFound?: Boolean,
  addthisLoaded?: Boolean
}

declare global {
  interface Window { addthis: any; }
}

export class NewsDetailsPage extends React.Component<IProps,IState> {
  
  componentDidMount(){
    const { getPage } = this.props;
    getPage('our-news');
    TagManager.dataLayer(tagManagerArgs);
  }

  async componentDidUpdate() {
    const { page, match } = this.props;
    const stateNews = this.state && this.state.newsItem
    const notFound = this.state && this.state.notFound
    const modules = page.modules
    if(!stateNews && modules && modules.length > 0 && !notFound){
      const { params } = match
      const {cmsPageId} = params
      const newsItem = modules.find(m=>m.slug === cmsPageId)
      if(newsItem){
        const body = newsItem && await richTextFromMarkdown(newsItem.body)
        this.setState({body, newsItem, notFound:false})
      } else{
        this.setState({notFound:true})
      }
    }
    setTimeout(() => {
      if ('addthis' in window) {
        if (typeof(window.addthis.layers) === "function" && typeof(window.addthis.layers.refresh) === "function") {
          window.addthis.layers.refresh();
        }
      }  
    }, 500);
  }

  handleScriptLoad() {
    if ('addthis' in window) {
      this.setState({ addthisLoaded: true });
      window.addthis.init();
    }
  }

  render(){
    const { isPageLoading } = this.props;

    return (
      <main className={classNames('Main', { [`Main--our-news`]: 'our-news' })}>

      <Fragment>
          <MetaTags>
            <title>Overseer News</title>
            <meta name="description" content="Latest news from Overseer" />
            <meta property="og:title" content="Overseer news" />
          </MetaTags>
          <Script
              url="https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5df06516eab6213c"
              onLoad={this.handleScriptLoad.bind(this)}
            />

          <AccessibleLoadNotifier isLoading={isPageLoading} />

          <PoseGroup>
            {!isPageLoading && this.state && this.state.newsItem && (
              <Container key="our-news">
                <div className="Page">
                  <section>
                    <div className="Container NewsArticle-item  u-mB-lg">
                      <div className="Grid">
                        <div className="Grid-cell">
                          <h1>{this.state.newsItem.title}</h1>
                          <p className="NewsArticle-item-light">{this.state.newsItem.description}</p>
                          <div className="u-flex">
                            <div className="NewsArticle-date">
                                <Icon name="calendar" className="SVG SVG--sm" />
                                <div className="fineprint u-textGrey u-pL-sm u-pT-xs">{new Date(this.state.newsItem.publishDate).toDateString()} - {this.state.newsItem.timeToRead}</div>
                            </div>
                          </div>
                        </div>
                        {this.state.newsItem.heroImage && this.state.newsItem.heroImage.file && this.state.newsItem.heroImage.file.url &&
                        <div className="Hero-imageContainer Grid-cell u-textCenter u-pT-lg">
                          <CmsImageConnected
                            className="Hero-image NewsArticle-xlg-image"
                            mediaType="large"
                            src={this.state.newsItem.heroImage.file.url}
                            alt={this.state.newsItem.heroImage.description || ''}
                            fit={{
                              small: { h: 468, w: 720 },
                              medium: { h: 370, w: 558 },
                              large: { h: 490, w: 743 },
                              xxLarge: { h: 603, w: 916 },
                            }}
                          />
                        </div>
                        }
                        <div className="Grid-cell u-textCenter u-pT-lg">
                        {this.state.addthisLoaded === true && (
                            <div className="addthis_inline_share_toolbox" data-url={window.URL} data-title={this.state.newsItem.title} />
                        )}
                        </div>
                        <div className="Grid-cell u-pT-lg">
                          <RichText text={this.state.body} />
                        </div>
                        <div className="Grid-cell u-pT-sm u-textCenter">
                          <Button className="Button Button--hasShadow Button--primary" isLinkButton={true} label="Back to news" url="../our-news" /> 
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="Background Background--top" aria-hidden />
                <div className="Background Background--middle" aria-hidden />
                <div className="Background Background--bottom" aria-hidden />
              </Container>
            )}
            {this.state && this.state.notFound &&
              <Container key="our-news">
              <div className="Page">
                <section>
                  <div className="Container NewsArticle-item u-pB-lg">
                    <div className="Grid">
                      <div className="Grid-cell u-textCenter">
                        <h1>Not found</h1>
                      </div>
                      <div className="Grid-cell u-textCenter">
                        <p>Oh no! It looks like the article you have linked to no longer exists. Click on the button below to view our latest news.</p>
                      </div>
                      <div className="Grid-cell u-pT-sm u-textCenter">
                        <Button className="Button Button--hasShadow Button--primary" isLinkButton={true} label="Back to news" url="../our-news" /> 
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="Background Background--top" aria-hidden />
              <div className="Background Background--middle" aria-hidden />
              <div className="Background Background--bottom" aria-hidden />
            </Container>
            }
          </PoseGroup>
        </Fragment>

      </main>
    );
  }
};





