import React, { Fragment, useState } from 'react';
import SwiperWrapper, { SwiperInstance } from 'react-id-swiper';
import classnames from 'classnames';

const DEFAULT_OPTIONS = {
  spaceBetween: 24,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
    dynamicBullets: true,
  },
  slideToClickedSlide: false,
  noSwipingSelector: 'button',
};

interface IProps {
  items: React.ReactNode[];
  options?: any;
  hasButtons?: boolean;
}

export const Swiper = (props: IProps) => {
  const { items, options, hasButtons } = props;
  const slidesPerView: number = (options && options.slidesPerView) || 0;
  const isNextInitiallyDisabled = items.length <= slidesPerView;

  const [swiper, updateSwiper]: [SwiperInstance, SwiperInstance] = useState(null);
  // Prev will always be disabled to begin with since we start at the first slide
  const [prevButtonDisabled, updatePrevButtonState] = useState(true);
  const [nextButtonDisabled, updateNextButtonState] = useState(isNextInitiallyDisabled);

  const prevButtonClass = classnames('swiper-button-prev', {
    'swiper-button-disabled': prevButtonDisabled,
  });
  const nextButtonClass = classnames('swiper-button-next', {
    'swiper-button-disabled': nextButtonDisabled,
  });

  const goNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <Fragment>
      <SwiperWrapper
        getSwiper={(swiperLoc: SwiperInstance | null) => {
          if (swiperLoc) {
            // SlideChange is fired on both prev/next button clicks and mouse/touch drags
            swiperLoc.on('slideChange', () => {
              if (swiperLoc) {
                updatePrevButtonState(swiperLoc.isBeginning);
                updateNextButtonState(swiperLoc.isEnd);
              }
            });

            // Init swiper
            updateSwiper(swiperLoc);
          }
        }}
        WrapperEl="ul"
        {...DEFAULT_OPTIONS}
        {...options}
      >
        {items.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={idx} className="Swiper-slide">
            {item}
          </li>
        ))}
      </SwiperWrapper>

      {hasButtons && (
        <Fragment>
          <button type="button" className={prevButtonClass} onClick={() => goPrev()}>
            <span className="u-hiddenVisually">Previous</span>
          </button>
          <button type="button" className={nextButtonClass} onClick={() => goNext()}>
            <span className="u-hiddenVisually">Next</span>
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};
