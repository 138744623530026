import React, { Fragment } from 'react';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Button } from 'views/components/Button/Button';
import { Icon } from 'views/components/Icon/Icon';
import { IMedia } from 'types';

interface IProps {
  title: string;
  text: string;
  buttonLabel: string;
  buttonLink?: string;
  mediaLink?: IMedia;
  iconName: string;
}

export const Notification = (props: IProps) => {
  const {
    title, text, buttonLabel, buttonLink, mediaLink, iconName,
  } = props;

  return (
    <Fragment>
      <ComponentInfoConnected zeplinId="NOTF01-01a" shouldShowMeta={false}/>

      <div className="Notification ModuleWrapper">
        <div className="Container">
          <div className="Notification-wrapper">
            <div className="Notification-content">
              <div className="Notification-icon SVG" aria-hidden>
                <Icon name={iconName} />
              </div>
              <div className="Notification-text">
                <h2 className="u-mB-0 u-mT-0">{title}</h2>
                <p className="u-mB-0 u-mT-0">{text}</p>
              </div>
            </div>

            <Button
              className="Notification-button Button Button--secondary"
              label={buttonLabel}
              isLinkButton
              url={!buttonLink && mediaLink ? mediaLink.file.url : buttonLink}
              shouldOpenNewTab={!!(mediaLink && mediaLink.file.url)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
