import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Swiper } from 'views/components/Swiper/Swiper';
import { LargieSmalls } from 'views/components/LargieSmalls/LargieSmalls';
import { WithFilter } from 'views/enhancers/WithFilter/WithFilter';

interface IVideo {
  estimatedTime?: string;
  tags: string[];
  text: string;
  title: string;
  videoId: string;
}

interface IProps {
  idSlug?: string;
  items: IVideo[];
  itemsToDisplay: IVideo[];
  tagClassName?: string;
  title: string;
}

const VideoSlider = WithFilter(({ itemsToDisplay }: IProps) => {
  const [selectedItem, selectItem] = useState(itemsToDisplay[0]);

  const renderItem = (
    {
      title, text, estimatedTime, videoId,
    }: IVideo,
    index: number,
    isActive?: boolean,
  ) => (
    <button
      type="button"
      key={`Video-${title}`}
      className={classNames('Videos-item Grid-cell', {
        'u-width1of1': isActive,
      })}
      onClick={() => !isActive && selectItem(itemsToDisplay[index])}
    >
      <div className="Videos-itemWrapper">
        {videoId && (
          <Fragment>
            {isActive ? (
              <div className="Videos-videoWrapper">
                <iframe
                  title={title}
                  className="Videos-video"
                  src={`//www.youtube.com/embed/${videoId}`}
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
            ) : (
              <div className="Videos-thumbnail">
                <img src={`//img.youtube.com/vi/${videoId}/0.jpg`} alt="" aria-hidden />
              </div>
            )}
          </Fragment>
        )}

        <div className="Videos-content">
          <strong className={classNames('u-block', { h3: isActive })}>{title}</strong>

          <p>
            {text}
            <span className="u-hiddenVisually"><FormattedMessage id="DURATION" /></span>
            <span>{estimatedTime && `(${estimatedTime})`}</span>
          </p>
        </div>
      </div>
    </button>
  );

  const renderSwiper = (size: string, slidesPerView: number, hasButtons: boolean) => {
    const setupProps = {
      items: itemsToDisplay.map((item: IVideo, index: number) => renderItem(item, index)),
      options: { shouldSwiperUpdate: true },
    };

    return (
      <div key={size} className={classNames('Videos-carousel', { Grid: size === 'small' })}>
        <Swiper
          {...setupProps}
          hasButtons={hasButtons}
          options={{
            ...setupProps.options,
            slidesPerView,
          }}
        />
      </div>
    );
  };

  return (
    <div className="Videos-wrapper Grid-cell Grid-cell--center">
      <div className="Grid">
        {renderItem(selectedItem, 0, true)}

        <div className="Grid-cell Grid-cell--center">
          <LargieSmalls
            small={renderSwiper('small', 2, false)}
            medium={renderSwiper('med', 4, true)}
            xLarge={renderSwiper('small', 6, true)}
          />
        </div>
      </div>
    </div>
  );
});

export const Videos = (props: IProps) => {
  const {
    idSlug,
    title,
  } = props;

  return (
    <div id={idSlug} className="Videos ModuleWrapper ModuleWrapper--dark">
      <div className="Container">
        <div className="Grid">
          <div className="Grid-cell u-width1of1 u-textCenter">
            <h2 className="h1 u-mB-md u-mT-0">{title}</h2>
            <VideoSlider tagClassName="Tag--dark" {...props} />
          </div>
        </div>
      </div>
    </div>
  );
};
