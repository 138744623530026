import React from 'react';
import classNames from 'classnames';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { IFaq, FAQItem } from 'views/components/modules/FAQList/FAQItem/FAQItem';

interface IProps {
  faqList: IFaq[];
  title?: string;
}

export const FAQList = (props: IProps) => {
  const {
    faqList,
    title,
  } = props;

  if (!faqList || faqList.length === 0) {
    return null;
  }

  // Split the array into two columns
  const indexToSplit = Math.ceil(faqList.length / 2);

  const left = faqList.slice(0, indexToSplit);
  const right = faqList.slice(indexToSplit, faqList.length);

  return (
    <section className="FAQList ModuleWrapper">
      <ComponentInfoConnected zeplinId={`FAQ01-01${title ? 'b' : 'a'}`}  shouldShowMeta={false}/>

      <div className="Container">
        <div className="Grid">
          {title && (
            <h2 className="h1 FAQList-title">{title}</h2>
          )}

          <div role="tablist" className="FAQList-list Grid-cell u-width1of1 u-lg-width1of2">
            {left.map(faq => (
              <div className={classNames('FAQList-item')} key={faq.id}>
                <FAQItem {...faq} />
              </div>
            ))}
          </div>
          <div role="tablist" className="FAQList-list Grid-cell u-width1of1 u-lg-width1of2">
            {right.map(faq => (
              <div className={classNames('FAQList-item')} key={faq.id}>
                <FAQItem {...faq} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
