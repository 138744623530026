import React from 'react';

interface IProps {
  src: string;
  height: number;
  idSlug?: string;
}

export const Iframe = (props: IProps) => {
  const {
    src,
    idSlug,
    height,
  } = props;

  return (
    <section id={idSlug}>
        <div className="Container">
            <iframe title="National Reporting" src={src} frameBorder="0" width="100%" id="nr" height={`${height}px`}/>
        </div>
    </section>
  );
};
