import {
  applyMiddleware, combineReducers, createStore, compose,
} from 'redux';

// import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createResponsiveStateReducer, responsiveStoreEnhancer } from 'redux-responsive';
import createSagaMiddleware from 'redux-saga';
// import { createBrowserHistory } from 'history';
import { sagaMonitor } from '../tools/utilities/saga-monitor';
import { trackError } from '../tools/analytics/index';

import * as reducers from './modules';
import { rootSaga } from './modules/root-saga';

// export const history = createBrowserHistory();

const rootReducer = combineReducers({
  browser: createResponsiveStateReducer({
    small: '(max-width: 767.98px)', // Small
    medium: '(min-width: 768px) and (max-width:1023.98px)', // Medium
    large: '(min-width: 1024px) and (max-width:1279.98px)', // Large
    xLarge: '(min-width: 1280px) and (max-width:1599.98px)', // X-Large
    xxLarge: '(min-width: 1600px)', // XX-Large
  }),
  ...reducers,
  // router: connectRouter(history),
});

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
  onError: trackError,
});

// routerMiddleware(history)
const middleware = [sagaMiddleware];

let composeEnhancers = compose;

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  // eslint-disable-next-line no-underscore-dangle
  const composeWithDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension;
  }
}

export const store = createStore(
  rootReducer,
  {},
  composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware)),
);

sagaMiddleware.run(rootSaga);
