import React from 'react';
import classNames from 'classnames';

interface IProps {
  label: string;
  className?: string;
}

export const Loader = ({ label, className }: IProps) => {
  return (
    <div className={classNames( 'Spinner', className )} role="progressbar" aria-label={label} aria-busy="true">
      <i /><i /><i /><i /><i /><i />
    </div>
  );
};
