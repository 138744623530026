import React from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import MetaTags from 'react-meta-tags';
import { trackPageView } from 'tools/analytics/index';
import { Document } from '@contentful/rich-text-types';

import { Header } from 'views/components/modules/Header/Header';

interface IProps extends InjectedIntlProps {}

const PageNotFoundComponent = ({ intl }: IProps) => {
  const title = `${intl.formatMessage({ id: 'APP_TITLE' })} - ${intl.formatMessage({
    id: 'PAGE_NOT_FOUND',
  })}`;

  // Send trackPageView analytics event as this page isn't CMS driven
  trackPageView(title);

  return (
    <main className="Main Main--pageNotFound">
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content={intl.formatMessage({ id: 'PAGE_NOT_FOUND' })} />
        <meta property="og:title" content={title} />
      </MetaTags>

      <Header
        displayVariation="Header"
        title={intl.formatMessage({ id: 'SOMETHING_HAS_GONE_WRONG' })}
        richText={{
          data: {},
          content: [
            {
              data: {},
              content: [
                {
                  data: {},
                  marks: [],
                  value: intl.formatMessage({ id: '404' }),
                  nodeType: 'text',
                },
              ],
              nodeType: 'paragraph',
            },
          ],
          nodeType: 'document',
        } as Document}
        buttonLabels={[intl.formatMessage({ id: 'RETURN_HOME' })]}
        buttonLinks={['/']}
      />
    </main>
  );
};

export const PageNotFound = injectIntl(PageNotFoundComponent);
