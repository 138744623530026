import React from 'react';
import { IImage, IMedia } from 'types';

interface IProps {
  title: string;
  description: string;
  backgroundImage: IImage;
  video?: IMedia;
  youtubeUrl?: string;
  idSlug?: string;
}

export const MediaBanner = (props: IProps) => {
  const {
    title,
    description,
    video,
    youtubeUrl,
    backgroundImage,
    idSlug,
  } = props;

  const isUploadedVideo = video && video.file;
  if (!isUploadedVideo && !youtubeUrl) return null;


  return (
    <div id={idSlug} className="MediaBanner" style={{backgroundImage: `url(${backgroundImage.file.url})` }}>
      <div className="Container">
      <div className="Grid Grid--alignMiddle">
        <div className="Grid-cell u-width1of1 u-md-width1of2">
          <div className='MediaBanner_text'>
            <h2 className="MediaBanner_title">{title}</h2>
            <p className="MediaBanner_description">{description}</p>
          </div>
        </div>
        <div className='Grid-cell u-width1of1 u-md-width1of2'>
          {youtubeUrl &&
          <div className='MediaBanner_youtube'>
            <iframe 
              width="560" 
              title={title}
              height="315" 
              src={youtubeUrl}  
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
            </iframe>
          </div>
          }
          {video && video.file && !youtubeUrl &&
          <video controls id="video_background">
            <source src={video.file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          }
        </div>
      </div>
      </div>
    </div>
  );
};
