import React from 'react';
import { IImage } from 'types';
import { Document } from '@contentful/rich-text-types';
import { Icon } from 'views/components/Icon/Icon';
import { Link } from 'react-router-dom';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';

interface IProps {
  title: string;
  slug: string;
  heroImage: IImage;
  description: Document;
  body: Document;
  publishDate: Date;
  tags: Array<string>;
  fullWidth: Boolean;
  timeToRead: string;
}

export const NewsSummary = (props: IProps) => {
  const {
    title,
    slug,
    heroImage={file:{url:''}, description:''},
    description,
    publishDate,
    fullWidth,
    timeToRead
  } = props;

  return (

    <Link to={`/articles/${slug}`} >
      {fullWidth ?
      <div className="Grid u-pL-sm u-pR-sm" id={slug}>
        <div className="NewsArticle-item">
        <div className="Grid-cell u-lg-width1of2">
            {heroImage && heroImage.file && heroImage.file.url &&
            <CmsImageConnected
              className="NewsArticle-lg-image"
              mediaType="large"
              src={heroImage.file.url}
              alt={heroImage.description || ''}
              fit={{
                small: { h: 468, w: 720 },
                medium: { h: 370, w: 558 },
                large: { h: 490, w: 743 },
                xxLarge: { h: 603, w: 916 },
              }}
            />
            }
          </div>
          <div className="u-pL-md Grid-cell u-lg-width1of2">
              <h3 className="h2 u-mT-xs u-mB-xs">{title}</h3>
              <p className="u-mB-0 u-mT-xs NewsArticle-item-light">{description}</p>
              <div className="NewsArticle-date">
                  <Icon name="calendar" className="SVG SVG--sm" />
                  <div className="fineprint u-textGrey u-pL-sm u-pT-xs">{new Date(publishDate).toDateString()} - {timeToRead}</div>
              </div>    
          </div>
        </div>
      </div>
      :
      <div className="NewsArticle-item small">
          {heroImage && heroImage.file && heroImage.file.url &&
          <CmsImageConnected
              className="NewsArticle-sm-image"
              mediaType="large"
              src={heroImage.file.url}
              alt={heroImage.description || ''}
              fit={{
                small: { h: 468, w: 720 },
                medium: { h: 370, w: 558 },
                large: { h: 490, w: 743 },
                xxLarge: { h: 603, w: 916 },
              }}
            />
          }
          <h3 className="h2 u-mT-xs u-mB-xs">{title}</h3>
          <p className="u-mB-0 u-mT-xs NewsArticle-item-light">{description}</p>
          <div className="u-flex">
            <div className="NewsArticle-date">
                <Icon name="calendar" className="SVG SVG--sm" />
                <div className="fineprint u-textGrey u-pL-sm u-pT-xs">{new Date(publishDate).toDateString()} - {timeToRead}</div>
            </div>
          </div>
      </div>}
    </Link>

  );
};
