import { createSelector } from 'reselect';

const getComponentProps = (state, props) => props;
const getIdFromPath = createSelector(
  [getComponentProps],
  (props) => {
    if (!props || !props.match) {
      return null;
    }

    return props.match.params.id;
  },
);

const getCurrentLocation = state => state.app.currentLocation;
const isLoading = state => state.app.isLoading;
export const isMenuOpen = state => state.app.isMenuOpen;

export const isViewportBelowLarge = state => state.browser.lessThan.large;

export const isViewportSmall = state => state.browser.is.small;
export const isViewportMedium = state => state.browser.is.medium;
export const isViewportLarge = state => state.browser.is.large;
export const isViewportXLarge = state => state.browser.is.xLarge;
export const isViewportXXLarge = state => state.browser.is.xxLarge;
export const mediaType = state => state.browser.mediaType;

export default {
  getComponentProps,
  getIdFromPath,

  getCurrentLocation,
  isLoading,
  isMenuOpen,
};
