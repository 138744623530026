import React from 'react';

import { IS_PRODUCTION } from 'redux/modules/app/constants';

interface IProps {
  zeplinId: string;
  shouldShowMeta?: boolean;
}

export const ComponentInfo = ({ zeplinId, shouldShowMeta }: IProps) => {
  if (IS_PRODUCTION || !shouldShowMeta) {
    return null;
  }

  return (
    <div className="ComponentInfo">
      <span className="ComponentInfo-id">{zeplinId}</span>
      <hr className="ComponentInfo-border" />
    </div>
  );
}
