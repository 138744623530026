/* eslint-disable react/jsx-filename-extension */

import 'react-app-polyfill/ie11';
import 'core-js/es/object';
import 'core-js/es/string/includes';
import 'core-js/es/array';
import 'core-js/es/weak-set';
import 'core-js/es/string/starts-with';

import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProvider, addLocaleData } from 'react-intl';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import ReactGA from 'react-ga';
import en from 'react-intl/locale-data/en';
import TagManager from 'react-gtm-module';
import './styles/app.scss';
import { ScientificModelFeed } from 'views/components/ScientificModel/ScientificModelFeed';
import { AkuakuProvider } from 'contexts/AkuakuContext';
import {
  IS_DEVELOPMENT,
  GA_TOKEN,
} from './redux/modules/app/constants';
import defaultIntl from './tools/i18n/en-nz.json';
import { store } from './redux/root-reducer';
import { Wrapper } from './views/pages/Wrapper/Wrapper';
import { PageNotFound } from './views/pages/NotFound/NotFound';
import { GlobalHeader } from './views/components/modules/GlobalHeader/GlobalHeader';
import { Footer } from './views/components/Footer/Footer';
import { Preview } from './views/pages/Preview';
import { SiteBannerConnected } from './views/components/SiteBanner/SiteBannerConnected';
import { NewsSummaryPageConnected } from './views/components/NewsSummaryPage/NewsSummaryPageConnected';
import { NewsDetailsPageConnected } from './views/components/NewsDetailsPage/NewsDetailsPageConnected';
import { ReactComponent as SVGs } from './assets/svg-icons/all.svg';


const tagManagerArgs = {
  gtmId: 'GTM-P9VHWN4',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

// initialise react-intl with en-nz as default locale
addLocaleData(...en);

// initialise analytics
if (GA_TOKEN) {
  ReactGA.initialize(GA_TOKEN, {
    debug: false,
    titleCase: false,
  });
}

const ScrollToTop = ({ location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

render(
  <AkuakuProvider>
    <SVGs aria-hidden="true" />
    <Provider store={store}>
      <IntlProvider locale="en-NZ" messages={defaultIntl}>
        <BrowserRouter>
          <Route
            render={({ location }) => {
              const params = new URLSearchParams(location.search);
              const opts = params.get('hf');
              const hideHeaderFooter = opts === 'none';
              return (
                <>
                  <ScrollToTop location={location} />

                  {!hideHeaderFooter && <GlobalHeader />}

                  <Switch location={location}>


                    <Route path="/" exact component={routeProps => (<Wrapper {...routeProps} defaultPageId="home2" />)} />
                    <Route path="/our-news" exact component={routeProps => (<NewsSummaryPageConnected {...routeProps} />)} />
                    <Route path="/our-model-feed" exact component={ScientificModelFeed} />
                    <Route path="/articles/:cmsPageId" exact component={routeProps => (<NewsDetailsPageConnected {...routeProps} />)} />
                    <Route path="/:cmsPageId" component={routeProps => (<Wrapper {...routeProps} />)} />

                    {/* DEBUG ROUTE */}
                    {IS_DEVELOPMENT && (
                      // @TODO Investigate lazy loading this using webpack dynamic import
                      // That way it wouldn't be included in the bundle
                      // It would save ~1kb so probably not worth it at this stage
                      <Route exact path="/preview" component={Preview} />
                    )}

                    {/*
                      @NOTE: If new static routes are added, they will need their
                      own PoseGroup which has the same delays/durations as the CmsPage PoseGroup
                    */}

                    {/*
                      Dynamic routes could be added here.
                      A CheckCms component could hit the CMS to see if a page exists,
                      if it exists, it can render a generic page with RenderModules,
                      otherwise, it can render the PageNotFound component.
                    */}
                    {/* <Route component={CheckCms} /> */}


                    <Route component={PageNotFound} />
                    {/* <Route component={Maintenance} /> */}
                  </Switch>

                  {!hideHeaderFooter && <Footer />}
                </>
              );
            }}
          />

          <SiteBannerConnected />
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  </AkuakuProvider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);
