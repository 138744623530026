export const MODEL_WIDTH = 2000;
export const MODEL_HEIGHT = 1500;
export const MODEL_HEIGHT_MOBILE = 4050;
export const MODEL_OFFSET = 24; // Offsets the left side of the model so drop shadows don't get cropped
export const BOX_WIDTH = 287;
export const BOX_HEIGHT = 64;
export const COLUMN_MARGIN = 110;
export const ROW_MARGIN = 18;
export const ITEM_CLASS = 'ScientificModel-node';
export const ACTIVE_ITEM_CLASS = 'is-active';
export const ITEM_ID_PREFIX = 'box-container-';
export const SELECTED_ITEM_CLASS = 'is-selected';
export const DEFAULT_OPTIONS_ITEM = {
  className: 'Rectangle',
  width: BOX_WIDTH,
  height: BOX_HEIGHT,
  stroke: '#f5f5f5',
  fill: 'transparent',
};

export const FARM_INPUTS_ITEM = {
  ...DEFAULT_OPTIONS_ITEM,
  stroke: '#509bc2',
};

export const MODELS_COMPONENTS_ITEM = {
  ...DEFAULT_OPTIONS_ITEM,
  stroke: '#f6931c',
};

export const OVERSEER_RESULTS_ITEM = {
  ...DEFAULT_OPTIONS_ITEM,
  stroke: '#3682ff',
};

export const CATEGORIES = {
  FARM_INPUTS: 'Farm inputs',
  MODELS_COMPONENTS: 'Models components',
  OVERSEER_RESULTS: 'Overseer results',
};

export const DROP_SHADOW_COLOURS = {
  inputs: '#509bc2',
  components: '#f6931c',
  results: '#3682ff',
};
