import React from 'react';
import classNames from 'classnames';

import { Checkbox } from 'views/components/Checkbox/Checkbox';

interface IProps {
  filters: string[];
  tagClassName?: string;
  onChangeFilters: any;
  selectedFilters: string[];
}

export const FilterList = (props: IProps) => {
  const {
    filters, tagClassName, onChangeFilters, selectedFilters,
  } = props;

  return (
    <ul className="FilterList">
      {filters.map((filter) => {
        const hasSelectedFilter = selectedFilters.includes(filter);

        return (
          <li key={`Filter-${filter}`} className="FilterList-item">
            <Checkbox
              className={classNames('Tag', tagClassName, {
                'is-selected': hasSelectedFilter,
              })}
              label={filter}
              value={filter}
              onChange={(event: any) => onChangeFilters(event)}
            />
          </li>
        );
      })}
    </ul>
  );
};
