// Utility functions used for formatting data received from the contentful cms

const formatContent = (item: any) => {
  const { fields, sys } = item;

  if (!fields) {
    return item;
  }

  const formattedEntry: any = {};

  Object.keys(fields).forEach((key) => {
    const field = fields[key];

    if (field.constructor === Object) {
      if (field.file) {
        formattedEntry[key] = {
          ...formatContent(field),
          ...formatContent(field.file),
        };
        delete formattedEntry[key].file;
      } else {
        formattedEntry[key] = formatContent(field);
      }
    } else if (Array.isArray(field)) {
      formattedEntry[key] = field.map(content => formatContent(content));
    } else {
      formattedEntry[key] = field;
    }

    return formattedEntry;
  });

  formattedEntry.id = sys.id;
  formattedEntry.contentType = sys.contentType && sys.contentType.sys ? sys.contentType.sys.id : null;

  return formattedEntry;
};

export const formatEntries = (content: any) => {
  const { items } = content;

  if (!items || !items.length) {
    return [];
  }

  return items.map((item: any) => formatContent(item));
};
