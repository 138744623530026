import React from 'react';

import { Button } from '../Button/Button';
import { OVERSEER_URLS } from '../../../redux/modules/app/constants';

export const UtilityMenu = () => (
  <nav className="UtilityMenu">
    <ul className="UtilityMenu-list">
      <li className="UtilityMenu-item">
        <Button
          className="Button Button--tertiary Button--hasShadow"
          isLabelIntl
          label="LOG_IN"
          url={OVERSEER_URLS.PORTAL_LOG_IN}
          isLinkButton
        />
      </li>
      <li className="UtilityMenu-item">
        <Button
          className="Button Button--primary Button--hasShadow"
          isLabelIntl
          label="SIGN_UP"
          isLinkButton
          url={OVERSEER_URLS.PORTAL_SIGN_UP}
        />
      </li>
    </ul>
  </nav>
);
