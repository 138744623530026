import React from 'react';
import classNames from 'classnames';

import { IProfile } from 'types';
import { Icon } from 'views/components/Icon/Icon';
import { Button } from 'views/components/Button/Button';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';

export const Profile = (props: IProfile) => {
  const {
    name, statement, profile, image, role, email, videoUrl, isGrid,
  } = props;

  // Only a button or email can be shown, not both
  const hasButton = videoUrl;
  const hasEmail = !hasButton && email;

  return (
    <div className={classNames('Profile', {
      'Profile--isInGrid': isGrid,
      'Profile--isEmail': hasEmail,
    })}>
      <div className="Profile-background">
        {image && image.file && (
          // <img className="Profile-image" src={image.file.url} alt={image.description || ''} />
          <CmsImageConnected
            mediaType="large"
            className="Profile-image"
            src={image.file.url}
            alt={image.description || ''}
            fit={{
              medium: { w: 96, h: 96 },
              xxLarge: { w: 128, h: 128 },
            }}
          />
        )}

        <div className="Profile-content">
          <div className="Profile-details">
            <strong className="Profile-name">{name}</strong>
            <span className="Profile-role">{role}</span>
          </div>
          <blockquote>{profile && profile.length > 0 ? profile : statement}</blockquote>

          {hasEmail && (
            <div className="Profile-email">
              <div className="Profile-email-content">
                <Icon name="email" className="SVG--sm u-mR-xs" />
                <p className="u-mT-0 u-mB-0">{email}</p>
              </div>
            </div>
          )}

          {hasButton && (
            <div className="Profile-button">
              <Button
                className="Button Button--tertiary Button--hasShadow"
                isLabelIntl
                isLinkButton
                label="WATCH_VIDEO"
                shouldOpenNewTab
                url={videoUrl}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
