import React from 'react';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Icon } from 'views/components/Icon/Icon';
import { Button } from 'views/components/Button/Button';

interface IList {
  title: string;
  text: string;
  iconName?: string;
}

interface IProps {
  items: IList[];
  title: string;
  buttonLabel: string;
  buttonLink: string;
}

export const List = (props: IProps) => {
  const {
    title, items, buttonLabel, buttonLink,
  } = props;
  const zeplinId = 'LIST01-01a';

  if (!items) {
    return null;
  }

  const renderListItems = ({ text, iconName, title: itemTitle }: IList) => (
    <li className="List-item Grid-cell u-width1of1 u-md-width1of3" key={itemTitle}>
      {iconName && <Icon name={iconName} className="List-icon SVG SVG--xs" />}
      {itemTitle && <h3 className="u-textMedium u-mB-0 u-mT-0">{itemTitle}</h3>}

      <p className="u-mB-0 u-mT-xs">{text}</p>
    </li>
  );

  return (
    <section className="List ModuleWrapper">
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false}/>

      <div className="Container">
        <h2 className="List-heading h1 u-textCenter">{title}</h2>
        <ul className="Grid List-list">{items.map(item => renderListItems(item))}</ul>

        <div className="List-button u-flex u-flexJustifyCenter u-width1of1">
          {buttonLabel && (
            <Button
              className="Button Button--secondary Button--hasShadow"
              label={buttonLabel}
              isLinkButton
              url={buttonLink}
            />
          )}
        </div>
      </div>
    </section>
  );
};
