import { Document } from '@contentful/rich-text-types';
import { IMedia } from 'types';

export const CMS = {
  GET_PAGE: 'CMS_GET_PAGE',
  GET_PAGE_SUCCESS: 'CMS_GET_PAGE_SUCCESS',
  GET_PAGE_FAIL: 'CMS_GET_PAGE_FAIL',

  GET_SCIENTIFIC_MODEL_ITEM: 'GET_SCIENTIFIC_MODEL_ITEM',
  GET_SCIENTIFIC_MODEL_ITEM_SUCCESS: 'GET_SCIENTIFIC_MODEL_ITEM_SUCCESS',
  GET_SCIENTIFIC_MODEL_ITEM_FAIL: 'GET_SCIENTIFIC_MODEL_ITEM_FAIL',
};

export interface IGetPage {
  type: typeof CMS.GET_PAGE,
  payload: {
    id: string;
  };
}

export interface IGetPageSuccess {
  type: typeof CMS.GET_PAGE_SUCCESS,
  payload: {
    id: string;
  };
}

export interface IScientificModelItem {
  title: string;
  type: string;
  overview: Document;
  modelsAndComponents?: Document;
  pdf?: IMedia,
  disablePrint: boolean;
}

export interface IGetScientificModelItem {
  type: typeof CMS.GET_SCIENTIFIC_MODEL_ITEM,
  payload: {
    id: string;
  };
}

export interface IGetScientificModelItemSuccess {
  type: typeof CMS.GET_SCIENTIFIC_MODEL_ITEM,
  payload: {
    id: string;
    data: IScientificModelItem[];
  };
}

export type IErrorType = typeof CMS.GET_PAGE_FAIL | typeof CMS.GET_SCIENTIFIC_MODEL_ITEM_FAIL;

export interface ICmsFail {
  type: IErrorType;
  payload: {
    id: string;
    error: any;
  };
}

export type CMSActionTypes = IGetPage |
  IGetPageSuccess |
  IGetScientificModelItem |
  IGetScientificModelItemSuccess |
  ICmsFail;

export function actionGetPage(id: string) : CMSActionTypes {
  return {
    type: CMS.GET_PAGE,
    payload: {
      id,
    },
  };
}

export function actionGetScientificModelItem(id: string) : CMSActionTypes {
  return {
    type: CMS.GET_PAGE,
    payload: {
      id,
    },
  };
}
