import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Document } from '@contentful/rich-text-types';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Button } from 'views/components/Button/Button';
import { RichText } from 'views/components/RichText/RichText';

type DISPLAY_VARIATION = 'Header' | 'Buttons';

interface IProps {
  buttonLabels?: string[];
  buttonLinks?: string[];
  displayVariation: DISPLAY_VARIATION;
  title?: string;
  richText?: Document;
}

export const Header = (props: IProps) => {
  const {
    displayVariation = 'Header',
    title,
    buttonLabels,
    buttonLinks,
    richText,
  } = props;
  const formattedVariation = displayVariation.toLowerCase();

  let zeplinId = 'HEADER01-01';

  if (displayVariation === 'Buttons' && buttonLabels && buttonLinks) {
    zeplinId += 'd';
  } else if (displayVariation === 'Header' && title && !richText && !buttonLabels) {
    zeplinId += 'a';
  } else if (displayVariation === 'Header' && title && richText && !buttonLabels) {
    zeplinId += 'b';
  } else if (displayVariation === 'Header' && title && richText && buttonLabels) {
    zeplinId += 'c';
  }

  const renderHeader = () => {
    const hasButtons = buttonLabels && buttonLinks;
    const isHeaderVariation = displayVariation === 'Header';
    const isButtonsVariation = displayVariation === 'Buttons' && hasButtons;

    if (isHeaderVariation) {
      return (
        <Fragment>
          <div className="Grid-cell Grid-cell--center u-md-width10of12 u-textCenter">
            <h2 className="Header-heading h1 u-mB-0 u-mT-0">{title}</h2>

            {richText && (
              <div className="Header-richText">
                <RichText text={richText} />
              </div>
            )}
          </div>

          {hasButtons && <RenderButtons buttonLabels={buttonLabels || []} buttonLinks={buttonLinks || []} />}
        </Fragment>
      );
    }

    if (isButtonsVariation) {
      return <RenderButtons buttonLabels={buttonLabels || []} buttonLinks={buttonLinks || []} />;
    }

    return null;
  };

  return (
    <section className={`Header Header--${formattedVariation} ModuleWrapper`}>
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false}/>

      <div className="Container">{renderHeader()}</div>
    </section>
  );
};

interface IRenderButtonProps {
  buttonLabels: string[];
  buttonLinks: string[];
}

const RenderButtons = ({ buttonLabels, buttonLinks }: IRenderButtonProps) => (
  <div className="Header-wrapper Grid">
    {buttonLabels.map((label: string, index: number) => {
      const buttonClassName = classnames('Header-button u-textCenter', {
        'Header-button--single': buttonLabels.length <= 1,
      });
      const url = buttonLinks[index];
      const shouldOpenNewTab = url && url.startsWith('http');

      if (url) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`Header-button-${label}${index}`} className={buttonClassName}>
            <Button
              isLinkButton
              className="Button Button--hasShadow"
              label={label}
              url={url}
              shouldOpenNewTab={shouldOpenNewTab}
            />
          </div>
        );
      }

      return console.warn(`Button with label (${label}) is missing corresponding Button Link`);
    })}
  </div>
);
