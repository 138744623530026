import React from 'react';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Icon } from 'views/components/Icon/Icon';

interface IItems {
  title: string;
  text: string;
  iconName?: string;
}

interface IProps {
  items: IItems[];
  title: string;
}

export const IconList = (props: IProps) => {
  const { title, items } = props;
  const zeplinId = 'ICONS01-01a';

  if (!items) {
    return null;
  }

  return (
    <section className="IconList ModuleWrapper">
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false}/>

      <div className="Container">
        <h2 className="IconList-heading h1 u-textCenter">{title}</h2>

        <ul className="Grid IconList-list">
          {items.map(({ text, iconName, title: itemTitle }: IItems, index) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`IconList-item-${itemTitle}${index}`}
              className="IconList-item Grid-cell u-width1of1 u-md-width1of3"
            >
              {iconName && (
                <div className="IconList-icon" aria-hidden>
                  <Icon name={iconName} className="SVG SVG--md" />
                </div>
              )}

              <div className="IconList-content">
                {itemTitle && <h3 className="h4 u-textMedium u-mB-0 u-mT-0">{itemTitle}</h3>}

                <p className="u-mB-0 u-mT-xs">{text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
