import farmInputsColumn1 from 'scientific-model/farm-inputs/column-1.json';

import modelsComponentColumn1 from 'scientific-model/models-components/column-1.json';
import modelsComponentColumn2 from 'scientific-model/models-components/column-2.json';
import modelsComponentColumn3 from 'scientific-model/models-components/column-3.json';

import overseerResultsColumn1 from 'scientific-model/overseer-results/column-1.json';
// import overseerResultsColumn2 from 'scientific-model/overseer-results/column-2';

import links from 'scientific-model/links';

export const MODEL = {
  farmInputs: [
    farmInputsColumn1,
  ],
  modelsAndComponents: [
    modelsComponentColumn1,
    modelsComponentColumn2,
    modelsComponentColumn3,
  ],
  overseerResults: [
    overseerResultsColumn1,
    // overseerResultsColumn2,
  ],
  links,
};
