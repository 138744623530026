import React from 'react';
import classNames from 'classnames';
import { Document } from '@contentful/rich-text-types';

import logoIcon from 'images/icon-blue-coloured-no-background.png';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Button } from 'views/components/Button/Button';
import { RichText } from 'views/components/RichText/RichText';
import { IMedia } from 'types';

interface IProps {
  buttonLabel?: string;
  buttonLink?: string;
  idSlug?: string;
  mediaLink?: IMedia;
  price?: string;
  priceLabel?: string;
  richText: Document;
  title: string;
}

export const CopyBlock = (props: IProps) => {
  const {
    buttonLabel, buttonLink, idSlug, mediaLink, price, priceLabel, richText, title,
  } = props;

  const hasPrice = price && priceLabel;
  const hasButton = buttonLabel && (buttonLink || mediaLink);

  let zeplinId = 'COPYBLOCK01-01';
  if (hasButton && !hasPrice) {
    zeplinId += 'a';
  } else if (hasPrice) {
    zeplinId += 'b';
  } else {
    zeplinId += 'c';
  }

  return (
    <section id={idSlug} className="CopyBlock ModuleWrapper">
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false}/>

      <div className="Container">
        <div className="Grid Grid--alignMiddle">
          <div className="Grid-cell u-width1of1 u-md-width2of3">
            <h3 className="CopyBlock-title">{title}</h3>

            <RichText text={richText} />
          </div>

          <div className="Grid-cell CopyBlock-button u-width1of1 u-md-width1of3">
            {hasPrice && (
              <div className="CopyBlock-price">
                <img className="CopyBlock-price-icon" src={logoIcon} aria-hidden alt="" />

                <h4 className="CopyBlock-price-title">{price}</h4>

                <p className="small u-mT-0 u-mB-0">{priceLabel}</p>
              </div>
            )}

            {hasButton && (
              <Button
                className={classNames('Button Button--hasShadow', {
                  'Button--tertiary': !hasPrice,
                  'Button--primary': hasPrice,
                })}
                isLinkButton
                label={buttonLabel}
                url={!buttonLink && mediaLink && mediaLink.file ? mediaLink.file.url : buttonLink}
                shouldOpenNewTab={!!(mediaLink && mediaLink.file && mediaLink.file.url)}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
