// NOTE: This is a generated file, nothing you do here matters
// The source of the all.svg file is located at './src/assets/svg-icons/all.svg'
// The script that generates this file is located at tools/svg-generator.js
// To rebuild this file run 'yarn run generate-svgs'
import React from 'react';

export default {
  'apple': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#apple" />
    </svg>
  ),
  'avocado': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#avocado" />
    </svg>
  ),
  'bull': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#bull" />
    </svg>
  ),
  'calendar': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <use xlinkHref="#calendar" />
    </svg>
  ),
  'compare-scenarios': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#compare-scenarios" />
    </svg>
  ),
  'cow': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#cow" />
    </svg>
  ),
  'deer': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#deer" />
    </svg>
  ),
  'email': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#email" />
    </svg>
  ),
  'environmental-reports': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#environmental-reports" />
    </svg>
  ),
  'error': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <use xlinkHref="#error" />
    </svg>
  ),
  'find': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#find" />
    </svg>
  ),
  'goat': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#goat" />
    </svg>
  ),
  'grains': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#grains" />
    </svg>
  ),
  'grapes': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#grapes" />
    </svg>
  ),
  'graph': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#graph" />
    </svg>
  ),
  'green-tick': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <use xlinkHref="#green-tick" />
    </svg>
  ),
  'kiwifruit': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#kiwifruit" />
    </svg>
  ),
  'lightbulb': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <use xlinkHref="#lightbulb" />
    </svg>
  ),
  'model-by-block': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#model-by-block" />
    </svg>
  ),
  'newzealand': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#newzealand" />
    </svg>
  ),
  'one': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#one" />
    </svg>
  ),
  'optimise-nutrients-use': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#optimise-nutrients-use" />
    </svg>
  ),
  'padlock': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#padlock" />
    </svg>
  ),
  'peaches': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#peaches" />
    </svg>
  ),
  'pig': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#pig" />
    </svg>
  ),
  'plan-ahead': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#plan-ahead" />
    </svg>
  ),
  'plant': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#plant" />
    </svg>
  ),
  'seeds': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#seeds" />
    </svg>
  ),
  'setup': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#setup" />
    </svg>
  ),
  'share': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#share" />
    </svg>
  ),
  'sheep': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#sheep" />
    </svg>
  ),
  'strategy': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#strategy" />
    </svg>
  ),
  'three': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#three" />
    </svg>
  ),
  'thumbsup': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#thumbsup" />
    </svg>
  ),
  'tractor': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#tractor" />
    </svg>
  ),
  'two': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <use xlinkHref="#two" />
    </svg>
  ),
  'vegetables': (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <use xlinkHref="#vegetables" />
    </svg>
  ),
};
