import React, { useState, ReactNode } from 'react';
import classnames from 'classnames';

export interface ITabs {
  initialTab?: number;
  headers: string[];
  bodies: ReactNode[];
  onTabChange?: (activeTabIndex: number) => void;
}

export const Tabs = ({
  initialTab,
  headers: tabs,
  bodies,
  onTabChange,
}: ITabs) => {
  const [activeTab, setActiveTab] = useState(initialTab || 0);

  const handleTabClick = (activeTabIndex: number) => {
    setActiveTab(activeTabIndex);

    if (onTabChange) {
      onTabChange(activeTabIndex);
    }
  };


  return (
    <div className="Tabs">
      <ul className="Tabs-list u-flex">
        {tabs.map((tab: string, index: number) => {
          const tabClass = classnames('Tabs-list-item', {
            'is-active': index === activeTab,
          });

          return (
            <li
              className={tabClass}
              key={`${tab}`}
            >
              <button
                className="Tabs-list-item-button"
                onClick={() => handleTabClick(index)}
                type="button"
              >
                {tab}
              </button>
            </li>
          );
        })}
      </ul>
      <div className="Tabs-body">{bodies[activeTab]}</div>
    </div>
  );
};
