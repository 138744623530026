import React, { Fragment, useEffect } from 'react';

import { IS_DEVELOPMENT } from 'redux/modules/app/constants';

import { Hero } from 'views/components/Hero/Hero';
import { MediaBanner } from 'views/components/modules/MediaBanner/MediaBanner';
import { Split } from 'views/components/modules/Split/Split';
import { Takeover } from 'views/components/modules/Takeover/Takeover';
import { SplitExpand } from 'views/components/modules/SplitExpand/SplitExpand';
import { Notification } from 'views/components/modules/Notification/Notification';
import { NotificationLong } from 'views/components/modules/NotificationLong/NotificationLong';
import { ProfileList } from 'views/components/modules/ProfileList/ProfileList';
import { List } from 'views/components/modules/List/List';
import { Callout } from 'views/components/modules/Callout/Callout';
import { Timeline } from 'views/components/modules/Timeline/Timeline';
import { CopyBlock } from 'views/components/modules/CopyBlock/CopyBlock';
import { IconList } from 'views/components/modules/IconList/IconList';
import { ScientificModel } from 'views/components/modules/ScientificModel/ScientificModel';
import { Header } from 'views/components/modules/Header/Header';
import { FeatureList } from 'views/components/modules/FeatureList/FeatureList';
import { BigList } from 'views/components/modules/BigList/BigList';
import { Iframe } from 'views/components/modules/Iframe/Iframe';
import { FAQList } from 'views/components/modules/FAQList/FAQList';
import { NewsSummary } from 'views/components/modules/NewsSummary/NewsSummary';
import { VideosAndResources } from 'views/components/modules/VideosAndResources/VideosAndResources';
import { PageNotFound } from 'views/pages/NotFound/NotFound';
import TagManager from 'react-gtm-module';

// @NOTE: module could be typed using the IProps from the component
export const AVAILABLE_MODULES = {
  moduleCallout: (module: any) => <Callout key={module.id} {...module} />,
  moduleCopyBlock: (module: any) => <CopyBlock key={module.id} {...module} />,
  moduleHero: (module: any) => <Hero key={module.id} {...module} />,
  moduleMediaBanner: (module: any) => <MediaBanner key={module.id} {...module} />,
  moduleIconList: (module: any) => <IconList key={module.id} {...module} />,
  moduleList: (module: any) => <List key={module.id} {...module} />,
  moduleNotification: (module: any) => <Notification key={module.id} {...module} />,
  moduleNotificationLong: (module: any) => <NotificationLong key={module.id} {...module} />,
  moduleProfileList: (module: any) => <ProfileList key={module.id} {...module} />,
  moduleSplit: (module: any) => <Split key={module.id} {...module} />,
  moduleIframe: (module: any) => <Iframe key={module.id} {...module} />,
  moduleSplitExpand: (module: any) => <SplitExpand key={module.id} {...module} />,
  moduleElements: (module: any) => <Header key={module.id} {...module} />,
  moduleFeatureList: (module: any) => <FeatureList key={module.id} {...module} />,
  moduleBigList: (module: any) => <BigList key={module.id} {...module} />,
  moduleFaq: (module: any) => <FAQList key={module.id} {...module} />,
  moduleResources: (module: any) => <VideosAndResources key={module.id} {...module} />,
  moduleNewsArticle: (module: any) => <NewsSummary key={module.id} {...module} />,
  moduleScienceModel: (module: any) => <ScientificModel key={module.id} {...module} />,
  moduleTakeover: (module: any) => <Takeover key={module.id} {...module} />,
  moduleTimeline: (module: any) => <Timeline key={module.id} {...module} />,
};

interface IModule {
  contentType: keyof typeof AVAILABLE_MODULES;
  id: string;
}

interface IProps {
  modules: IModule[];
  cmsPageId:string
}

export const RenderModules = (props: IProps) => {
  const { modules, cmsPageId } = props;

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        page: cmsPageId,
      },
      dataLayerName: 'PageDataLayer',
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [cmsPageId]);

  if (!modules || modules.length === 0) {
    return <PageNotFound />;
  }

  return (
    <Fragment>
      {modules.map((module) => {
        const { contentType, id } = module;

        // console.log(`Module #${idx}, ${contentType}`, module);

        if (AVAILABLE_MODULES[contentType]) {
          return [AVAILABLE_MODULES[contentType](module)];
        }

        // Module not found
        if (IS_DEVELOPMENT) {
          return <h1 key={id}>Module not found: {contentType}</h1>;
        }

        // Return null while in pre-prod and production environments
        return null;
      })}
    </Fragment>
  );
};
