import React, { useState } from 'react';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import classNames from 'classnames';

import { Button } from 'views/components/Button/Button';
import { UncontrolledField } from 'views/components/UncontrolledField';
import { Icon } from 'views/components/Icon/Icon';
import { MAILCHIMP_AUDIENCE_ID, MAILCHIMP_ID, MAILCHIMP_SERVER_LOC } from 'redux/modules/app/constants';

const URL = `//${MAILCHIMP_SERVER_LOC}.list-manage.com/subscribe/post?u=${MAILCHIMP_ID}&id=${MAILCHIMP_AUDIENCE_ID}`;

const SubscribeToNewsletter = ({ intl }: InjectedIntlProps) => {
  // STATE
  const [emailAddress, onChange] = useState('');
  const [emailIsEmpty, isEmailEmpty] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, subscribe: any) => {
    e.preventDefault();

    if (!emailAddress) {
      isEmailEmpty(true);
      return;
    }

    // Subscribe expects a form data object
    subscribe({ EMAIL: emailAddress });
  };

  return (
    <div className="SubscribeToNewsletter">
      <MailchimpSubscribe
        url={URL}
        render={({ subscribe, status, message }) => {
          let emailError = '';
          if (status === 'error') {
            emailError = message;
          } else if (emailIsEmpty) {
            emailError = intl.formatMessage({ id: 'ENTER_YOUR_EMAIL_ADDRESS_EMPTY' });
          }

          const isError = status === 'error' || emailError;

          if (status === 'success') {
            return (
              <div className="SubscribeToNewsletter-success">
                <Icon className="SVG SVG--xs" name="green-tick" />
                <p className="SubscribeToNewsletter-success-message">{intl.formatMessage({ id: 'THANKS_FOR_SUBSCRIBING' })}</p>
              </div>
            );
          }

          return (
            <>
              <form
                className="SubscribeToNewsletter-form"
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e, subscribe)}
              >
                <UncontrolledField
                  type="email"
                  name="EMAIL"
                  isLabelHidden
                  label={intl.formatMessage({ id: 'ENTER_YOUR_EMAIL_ADDRESS' })}
                  value={emailAddress}
                  placeholder={intl.formatMessage({ id: 'ENTER_YOUR_EMAIL_ADDRESS' })}
                  onChange={(ev: any) => onChange(ev.target.value)}
                  className={classNames('Input', { 'has-error': isError })}
                  shouldShowErrorIcon={false}
                />

                <Button
                  type="submit"
                  label="SUBSCRIBE"
                  isLabelIntl
                  className="Button Button--secondary"
                  isLoading={status === 'sending'}
                  isDisabled={status === 'sending'}
                />
              </form>

              {/* Already subscribed error */}
              {/* ****@****.com is already subscribed to list Test. <a href="https://****.us3.list-manage.com/subscribe/send-email?e=a2FsbGFuQGFscGhlcm8uY29t&u=39bf4e47ec83e9c6f2f78a8b2&id=5ac2c40ba4">Click here to update your profile</a> */}

              {/* Invalid email domain error */}
              {/* 0 - The domain portion of the email address is invalid (the portion after the @: al) */}

              {/* Too many subscribe attempts */}
              {/* Too many subscribe attempts for this email address. Please try again in about 5 minutes. (#5624) */}

              {/* Too many recent sign up attempts */}
              {/* Recipient "****+1@****.com" has too many recent signup requests */}

              {isError && (
                <div className="SubscribeToNewsletter-status">
                  <Icon className="SVG SVG--xs" name="error" />
                  <p className="SubscribeToNewsletter-status-message fineprint" dangerouslySetInnerHTML={{ __html: emailError }} />
                </div>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

const SubscribeToNewsletterWithIntl = injectIntl(SubscribeToNewsletter);

export { SubscribeToNewsletterWithIntl as SubscribeToNewsletter };
