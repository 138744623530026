import React, { useContext, useEffect } from 'react';
import { IImage, IMedia } from 'types';
import { Button } from 'views/components/Button/Button';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';
import { AkuakuContext } from 'contexts/AkuakuContext';
interface IProps {
  title: string;
  video?: IMedia;
  description: string;
  logo: IImage;
  backgroundImage: IImage;
  autoplay: boolean;
  idSlug?: string;
  youtubeUrl?: string;
}

export const Takeover = (props: IProps) => {
  const {
    title,
    video,
    description,
    logo,
    backgroundImage,
    autoplay,
    youtubeUrl,
    idSlug,
  } = props;

  const [context = {}, setContext] = useContext(AkuakuContext);
  const isUploadedVideo = video && video.file;
  const videoCtrl = document.getElementById("video_background");

  useEffect(() => {
    // @ts-ignore
    if (!context.takeoverViewed && !context.hideHeader)
    {
      // @ts-ignore
      setContext(ctx => ({ ...ctx, hideHeader: true }));
    }
  }, [setContext, context]);

  useEffect(()=>{
    if(videoCtrl && !!autoplay) {
      videoCtrl.addEventListener("canplay", function() {
        setTimeout(function() {
    // @ts-ignore
        videoCtrl.play();
        }, 3000);
      });
    }
  }, [autoplay, videoCtrl]);


  const hideTakeover = () => {
    // @ts-ignore
    setContext(ctx => ({ ...ctx, hideHeader: false, takeoverViewed: true }));
  };
  if (!isUploadedVideo && !youtubeUrl) return null;


    // @ts-ignore
  if (context && context.takeoverViewed) return null;

  return (

    <div id={idSlug} className="Takeover" style={{backgroundImage: `url(${backgroundImage.file.url})` }} >
        <div className="Takeover_content">
          <h1>{title}</h1>
          {youtubeUrl &&
              <iframe 
                width="800" 
                style={{border:'2px solid white'}}
                title={title}
                height="450" 
                src={youtubeUrl}  
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
              </iframe>
          }
          {video && video.file && !youtubeUrl &&
          <div className="video_container">
            <video controls id="video_background">
                <source src={video.file.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            }

          <br />
          <Button onClick={hideTakeover} label="ENTER SITE" className="Button" />
          <h3>{description}</h3>

          <CmsImageConnected
            mediaType="small"
            src={logo.file.url}
            alt={logo.description || ''}
            fit={{
              small: { h: 43, w: 150 },
              medium: { h: 43, w: 150 },
              large: { h: 43, w: 150 },
              xxLarge: { h: 64, w: 222 },
            }}
          />
        </div>
    </div>




  );
};