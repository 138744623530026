import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { IScientificModelItem } from 'redux/modules/cms/actions';
import { IState } from 'redux/modules';
import { RichText } from 'views/components/RichText/RichText';
import { Document, BLOCKS, INLINES } from '@contentful/rich-text-types';

import { Button } from 'views/components/Button/Button';
import { Tooltip } from 'views/components/ScientificModel/Tooltip/Tooltip';
import { Tabs } from 'views/components/Tabs/Tabs';
import { ICONS } from 'assets/scientific-model-icons/icons';

const CATEGORIES = {
  INPUT: 'Input',
  MODEL: 'Model',
  COMPONENT: 'Component',
  RESULT: 'Result',
};

interface InfoPanelProps {
  onPanelClose: (id: string) => {};
  data: IScientificModelItem;
  isOpen: boolean;
  isLoading: boolean;
  itemLabelForError: string;
  itemIdForError: string;
}

export const InfoPanel = ({
  onPanelClose, data, isOpen, isLoading, itemLabelForError, itemIdForError,
}: InfoPanelProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Fix issue where panel would be in expanded state upon initial open when exiting
  // and entering the model.
  useEffect(() => {
    if (isOpen) {
      setIsExpanded(false);
    }
  }, [isOpen]);

  const isViewportMobile = useSelector((state: IState) => state.browser.is.small);

  const handleMenuClick = () => {
    if (isViewportMobile && isOpen) { // Menu can be expanded to full screen when on small screens
      setIsExpanded(!isExpanded);
    } else { // Menu can only be closed on large screens
      onPanelClose(itemIdForError);
    }
  };

  const {
    title,
    type,
    overview,
    modelsAndComponents,
    pdf,
    disablePrint,
  } = data || {};

  const mainClass = classnames('InfoPanel u-print-hidden', {
    'InfoPanel--noTabs': !overview || !modelsAndComponents,
    'is-active': isOpen,
    'is-expanded': isViewportMobile && isExpanded && isOpen,
  });

  const categoryClass = classnames('InfoPanel-category', {
    'InfoPanel-category--farmInput': type && type[0] === CATEGORIES.INPUT,
    'InfoPanel-category--model': type ? type[0] === CATEGORIES.MODEL || type[0] === CATEGORIES.COMPONENT : false,
    'InfoPanel-category--overseerResult': type && type[0] === CATEGORIES.RESULT,
  });

  const actionsClass = classnames('InfoPanel-actions', {
    'InfoPanel-actions--noPDF': !pdf,
  });

  return (
    <aside className={mainClass}>
      <button
        className="InfoPanel-dismiss"
        type="button"
        onClick={handleMenuClick}
      >
        <span className="u-hiddenVisually">Close panel</span>
      </button>
      {/* Check if we need a new loader here */}
      {isLoading && <p className="u-mL-md u-mR-md u-mT-md">Loading...</p>}
      {!isLoading && data && (
        <>
          <div className="u-pL-md u-pR-md">
            {type && <p className={categoryClass}>{type}</p>}
            <h3 className="InfoPanel-label">{title}</h3>
          </div>
          <RenderInfoPanelContent
            overview={overview}
            modelsAndComponents={modelsAndComponents}
          />
          <div className="InfoPanel-blur" />
          <div className={actionsClass}>
            {!disablePrint && (
              <Button
                icon="print"
                className="Button InfoPanel-actions-print"
                label="Print overview"
                onClick={() => {
                  window.print();
                }}
              />
            )}
            {pdf && (
              <Button
                isLinkButton
                className="Button InfoPanel-actions-pdf"
                label="Download PDF"
                url={pdf.file.url}
                shouldOpenNewTab
              />
            )}
          </div>
        </>
      )}
      {!isLoading && !data && (
        <p className="u-mL-md u-mR-md u-mT-md">Sorry, we couldn't find details about {itemLabelForError}.</p>
      )}
    </aside>
  );
};

interface IFormattedTextTag {
  open: string;
  close: string;
}

const ICON_REPLACEMENTS = {
  main: 'icon',
};

const setTags = (tagName: string): IFormattedTextTag => ({
  open: `[${tagName}]`,
  close: `[/${tagName}]`,
});

const extractTextContent = (text: string, replacement: string) => {
  const tags = setTags(replacement);
  const openIndex = text.indexOf(tags.open);
  const closeIndex = text.indexOf(tags.close);

  if (openIndex === -1 || closeIndex === -1) {
    return '';
  }

  return text.substring(openIndex + tags.open.length, closeIndex);
};

export const RICH_TEXT_OVERRIDES = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { title, description, file } = node.data.target.fields;

      return (
        <img
          title={title}
          alt={description}
          src={file.url}
        />
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node: any) => (
      <Tooltip
        title={node.data.target.fields.title}
        text={node.data.target.fields.description}
        label={node.data.target.fields.name}
      />
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => {
      let headingWithIconTags = node.content[0].value;
      let iconContent = extractTextContent(headingWithIconTags, ICON_REPLACEMENTS.main);

      if (!iconContent) {
        return <h4 className="InfoPanel-body-heading">{children}</h4>;
      }

      const iconTags = setTags(ICON_REPLACEMENTS.main);
      const headingTextOnly = headingWithIconTags.substring(0, headingWithIconTags.indexOf(iconTags.open));
      const headingWithIcons = [headingTextOnly];

      while (iconContent) {
        // @ts-ignore
        const icon = ICONS[iconContent];
        headingWithIcons.push(<img className="SVG SVG--md" src={icon} alt="" />);
        headingWithIconTags = headingWithIconTags.replace(iconTags.open, '').replace(iconTags.close, '');

        iconContent = extractTextContent(headingWithIconTags, ICON_REPLACEMENTS.main);
      }

      return <h4 className="InfoPanel-body-heading">{headingWithIcons}</h4>;
    },
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => <p className="InfoPanel-body-text">{children}</p>,
  },
};

interface IRenderInfoPanelContentProps {
  overview: Document;
  modelsAndComponents?: Document;
}

const RenderInfoPanelContent = ({ overview, modelsAndComponents }: IRenderInfoPanelContentProps) => {
  const useTabs = !!overview && !!modelsAndComponents;

  if (useTabs) {
    return (
      <Tabs
        headers={['Summary', 'Details']}
        bodies={[
          <RichText text={overview} options={RICH_TEXT_OVERRIDES} />,
          modelsAndComponents && <RichText text={modelsAndComponents} options={RICH_TEXT_OVERRIDES} />,
        ]}
      />
    );
  }

  return (
    <div className="InfoPanel-body">
      <RichText text={overview} options={RICH_TEXT_OVERRIDES} />
    </div>
  );
};
