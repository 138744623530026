import React, { Fragment } from 'react';
import classNames from 'classnames';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { IProfile } from 'types';
import { Profile } from 'views/components/modules/ProfileList/Profile/Profile';
import { LargieSmalls } from 'views/components/LargieSmalls/LargieSmalls';
import { Swiper } from 'views/components/Swiper/Swiper';

type DISPLAY_VARIATION = 'Grid' | 'Carousel';
const NUMBER_OF_ITEMS_IN_VIEW = 3;

interface IProps {
  title?: string;
  text?: string; // Text is optional but will only appear if there's a title
  profiles: IProfile[];
  displayVariation?: DISPLAY_VARIATION;
}

export const ProfileList = (props: IProps) => {
  const {
    title,
    text,
    profiles,
    displayVariation,
  } = props;

  const isCarousel = displayVariation === 'Carousel' && profiles.length > NUMBER_OF_ITEMS_IN_VIEW;

  // const isButton = profiles.some((profile: IProfile) => profile.videoUrl);
  const isEmail = profiles.some((profile: IProfile) => profile.email);

  // Assume it's Button - PROF01, if there's emails then it's PROF02
  let zeplinId = 'PROF01-01';
  if (isEmail) {
    zeplinId = 'PROF02-01';
  }

  if (!displayVariation && profiles.length === NUMBER_OF_ITEMS_IN_VIEW && title) {
    zeplinId += 'a';
  } else if (!displayVariation && profiles.length === NUMBER_OF_ITEMS_IN_VIEW && !title) {
    // Variation B has no title, just profiles
    zeplinId += 'b';
  } else if (displayVariation === 'Grid') {
    zeplinId += 'c';
  } else if (displayVariation === 'Carousel') {
    zeplinId += 'd';
  }

  const renderBody = (isMedium?: boolean) => {
    // If it's medium and it's email then only show 2 profiles, otherwise we show 3
    const numberOfItemsInView = isEmail && isMedium ? 2 : NUMBER_OF_ITEMS_IN_VIEW;

    // Find which item indexes (rows of 3 or 2) need  a margin
    const numberOfRows = Math.floor(profiles.length / numberOfItemsInView);
    let itemsThatNeedMargin = 0;
    if (numberOfRows > 0) {
      itemsThatNeedMargin = (numberOfRows === 1 ? 1 : numberOfRows - 1) * numberOfItemsInView;
    }

    return (
      <Fragment>
        {/* IS CAROUSEL - Variation D */}
        {isCarousel && (
          <div key="med" className="ProfileList-carousel">
            <div className="ProfileList-boxShadow">
              <Swiper
                key={numberOfItemsInView}
                hasButtons
                options={{
                  slidesPerView: numberOfItemsInView,
                }}
                
                items={profiles.map(profile => (
                  <div className="ProfileList-item Grid-cell u-width1of1" key={profile.id}>
                    <Profile {...profile} />
                  </div>
                ))}
              />
            </div>
          </div>
        )}

        {/* IS GRID - Variation C */}
        {!isCarousel && (
          <ul className="ProfileList-list Grid u-pB-0">
            {profiles.map((profile, idx) => {
              const hasMarginBottom = idx < itemsThatNeedMargin;

              const listItemClasses = classNames(
                'ProfileList-item Grid-cell u-width1of1',
                { 'has-margin': hasMarginBottom },
                { 'u-md-width1of3': numberOfItemsInView === 3 },
                { 'u-md-width1of2 u-lg-width1of3': numberOfItemsInView === 2 },
              );

              return (
                <li className={listItemClasses} key={idx}>
                  {/* Not in a carousel so need to modify the Profile component as it was built for carousel */}
                  <Profile {...profile} isGrid />
                </li>
              );
            })}
          </ul>
        )}
      </Fragment>
    );
  };

  return (
    <section className="ProfileList ModuleWrapper">
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false}/>

      <div className="Container">
        <div className="Grid">
          {title && (
            <div className="ProfileList-header Grid-cell Grid-cell--center u-width1of1 u-md-width2of3">
              <h2 className="h1 u-mB-xs u-mT-0">{title}</h2>
              {text && (
                <p className="h4 u-textLight u-mT-0 u-mB-0 u-pT-xs">{text}</p>
              )}
            </div>
          )}

          <div className="Grid-cell Grid-cell--center">
            <LargieSmalls
              small={
                (
                <div key="small" className="ProfileList-list Grid">
                  <div className="ProfileList-boxShadow u-width1of1">
                    <Swiper
                      items={profiles.map(profile => (
                        <div className="ProfileList-item Grid-cell u-width1of1" key={profile.id}>
                          <Profile {...profile} />
                        </div>
                      ))}
                    />
                  </div>
                </div>
              )}
              medium={() => renderBody(true)}
              large={() => renderBody()}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
