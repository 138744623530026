import React from 'react';
import classNames from 'classnames';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Button } from 'views/components/Button/Button';
import { IImage } from 'types';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';

interface IProps {
  title: string;
  text: string;
  image: IImage;
  hideImageAtSmall?: boolean;
  buttonLink?: string;
  buttonLabel?: string;
  hasLargeImage?: boolean;
}

export const Hero = (props: IProps) => {
  const {
    title,
    text,
    image,
    hideImageAtSmall,
    buttonLink,
    buttonLabel,
    hasLargeImage,
  } = props;

  let zeplinId = 'HER01-01';
  if (hasLargeImage) {
    zeplinId += 'c';
  } else if (hideImageAtSmall) {
    zeplinId += 'b';
  } else {
    zeplinId += 'a';
  }

  const heroClasses = classNames('Hero ModuleWrapper', {
    'Hero--hideMobileImage': hideImageAtSmall,
    'Hero--hasLargeImage': hasLargeImage,
  });

  const showButton = !!(buttonLabel && buttonLink);

  return (
    <div className={heroClasses}>
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false} />

      <div className="Container">
        <div className="Grid">
          <div
            className={classNames(
              'Grid-cell u-width1of1', {
                'u-md-width1of2 u-lg-width5of12': !hasLargeImage,
                'u-md-before1of12 u-md-width10of12 u-textCenter': hasLargeImage,
              },
            )}
          >
            <h2 className="Hero-title display">{title}</h2>
            <p className="Hero-text h2 u-textLight">{text}</p>

            {showButton && (
              <div className="Hero-button">
                <Button
                  className="Button Button--primary Button--hasShadow"
                  label={buttonLabel}
                  isLinkButton
                  shouldOpenNewTab
                  url={buttonLink}
                />
              </div>
            )}
          </div>

          {!hasLargeImage && image && image.file && (
            <div className="Hero-imageContainer Grid-cell u-md-before1of12 u-width1of1 u-md-width5of12 u-lg-width1of2">
              <CmsImageConnected
                className="Hero-image"
                mediaType="large"
                src={image.file.url}
                alt={image.description || ''}
                fit={{
                  small: { h: 268, w: 268 },
                  medium: { h: 273, w: 273 },
                  large: { h: 372, w: 440 },
                  xxLarge: { h: 457, w: 540 },
                }}
              />
            </div>
          )}

          {hasLargeImage && (
            <div className="Hero-imageContainer Grid-cell u-width1of1 u-md-before1of12 u-md-width10of12">
              <CmsImageConnected
                className="Hero-image"
                mediaType="large"
                src={image.file.url}
                alt={image.description || ''}
                fit={{
                  small: { h: 468, w: 720 },
                  medium: { h: 370, w: 558 },
                  large: { h: 490, w: 743 },
                  xxLarge: { h: 603, w: 916 },
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
