import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as d3 from 'd3';
import classnames from 'classnames';
  
import modelPreviewImage from 'assets/img-model.png';
  
import { MODEL } from 'scientific-model/model';
import {
  FARM_INPUTS_ITEM,
  MODELS_COMPONENTS_ITEM,
  OVERSEER_RESULTS_ITEM,
  ITEM_CLASS,
  DROP_SHADOW_COLOURS,
  MODEL_WIDTH,
  MODEL_HEIGHT,
  //BOX_WIDTH,
  //BOX_HEIGHT,
  //MODEL_OFFSET,
  MODEL_HEIGHT_MOBILE,
} from 'tools/utilities/model';
import { RICH_TEXT_OVERRIDES, InfoPanel } from 'views/components/ScientificModel/InfoPanel/InfoPanel';
import { addNodeEventHandlers, drawColumnGroup, setupRelationships } from 'tools/utilities/d3';
  
import {
  hideGlobalHeader, showGlobalHeader, hideGlobalFooter, showGlobalFooter,
} from 'redux/modules/app/actions';
  
  
import { Button } from 'views/components/Button/Button';
import { CMS } from 'redux/modules/cms/actions';
import { getScientificModelCurrentItem, isScientificModelItemLoading as isScientificModelItemLoadingSelector } from 'redux/modules/cms/selectors';
import { RichText } from 'views/components/RichText/RichText';
import TagManager from 'react-gtm-module';
  
const tagManagerArgs = {
  dataLayer: {
    page: 'Modelling',
  },
  dataLayerName: 'PageDataLayer',
};
  
export const ScientificModel = () => {
  const dispatch = useDispatch();
  
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(false);
  const [isViewingModel, setIsViewingModel] = useState(false);
  const [selectedItemLabel, setSelectedItemLabel] = useState('');
  const [selectedItemId, setSelectedItemId] = useState('');
  
  const isViewportMobile = useSelector(state => state.browser.is.small);
  const scientificModelCurrentItem = useSelector(getScientificModelCurrentItem);
  const isScientificModelItemLoading = useSelector(isScientificModelItemLoadingSelector);
  
  /**
     * Used to trigger the
     * info panel when clicking on an item in the Scientific Model
     */
  const showItemDetails = useCallback(
    ({ cmsId, label }) => {
      dispatch({ type: CMS.GET_SCIENTIFIC_MODEL_ITEM, payload: { id: cmsId } });
  
      setIsInfoPanelOpen(true);
      setSelectedItemLabel(label);
      setSelectedItemId(cmsId);
    },
    [dispatch],
  );
  
  /**
     * On mobile there's sort of an _intro_ for the Scientific model.
     * This function handles "closing" the intro.
     */
  const handleViewingModel = () => {
    setIsViewingModel(true);
    dispatch(hideGlobalHeader());
    dispatch(hideGlobalFooter());
  };
  
  /**
     * Takes the user back to the intro.
     * Executed when pressing the (<-) button on mobile.
     */
  const handleExitModel = () => {
    setIsViewingModel(false);
    setIsInfoPanelOpen(false);
    dispatch(showGlobalHeader());
    dispatch(showGlobalFooter());
  };
  
  /**
     * Handles zooming in and out of the model for when clicking either
     * of the zoom buttons
     * @param {boolean} isZoomingIn Indicates whether we zoom in or out
     */
  
  /*
  const handleZoom = (isZoomingIn) => {
    const svg = d3.select('#scientific-model-svg');
  
    const zoom = d3.zoom()
      .scaleExtent([1, 8])
      .on('zoom', () => {
        d3.select('#nodes').attr('transform', d3.event.transform);
        d3.select('#links').attr('transform', d3.event.transform);
        svg.attr('transform', 'translateZ(0)');
      });
  
    svg.transition().call(zoom.scaleBy, isZoomingIn ? 1.2 : 0.8);
  };
  */
  /**
     * Removes the selected state from the item in the Model and
     * closes the panel.
     * @param {string} itemId
     */
  const handleInfoPanelClose = () => {
    setIsInfoPanelOpen(false);
  };
  
  // If the model is rendered at large screen, it displays without a prompt
  useEffect(() => {
    if (!isViewportMobile && !isViewingModel) {
      setIsViewingModel(true);
    } else if (!isViewportMobile) { // Ensure the header/footer show again if the browser is resized
      dispatch(showGlobalHeader());
      dispatch(showGlobalFooter());
    }
  }, [isViewportMobile, isViewingModel, dispatch]);
  
  // If the viewport changes to mobile, reset the model state
  useEffect(() => {
    if (isViewportMobile) {
      setIsViewingModel(false);
      setIsInfoPanelOpen(false);
    }
  }, [isViewportMobile]);
  
  useEffect(() => {
    TagManager.dataLayer(tagManagerArgs);
  }, []);
  
  useEffect(() => {
    if (!isViewingModel) { return; } // Avoid appending multiple models
  
    const {
      farmInputs, modelsAndComponents, overseerResults, links,
    } = MODEL;
  
    const modelsAndComponentsColumnsOffset = farmInputs.length;
    const overseerResultsColumnOffset = farmInputs.length + MODEL.modelsAndComponents.length;
    const modelSvg = d3.select('#scientific-model-svg');
  
    // Stop multiple models being rendered
    if (!modelSvg.select('#nodes').empty()) {
      return;
    }
  
    // Draw all the nodes
    const nodesGroup = modelSvg.append('g').attr('id', 'nodes');
    drawColumnGroup(
      nodesGroup,
      `${ITEM_CLASS} ScientificModel-farmInputs-node`,
      farmInputs,
      FARM_INPUTS_ITEM,
      0,
      'Farm inputs',
      'Description of inputs',
      isViewportMobile,
    );
    drawColumnGroup(
      nodesGroup,
      `${ITEM_CLASS} ScientificModel-components-node`,
      modelsAndComponents,
      MODELS_COMPONENTS_ITEM,
      modelsAndComponentsColumnsOffset,
      'Models and components',
      'Description of models and components',
      isViewportMobile,
    );
    drawColumnGroup(
      nodesGroup,
      `${ITEM_CLASS} ScientificModel-results-node`,
      overseerResults,
      OVERSEER_RESULTS_ITEM,
      overseerResultsColumnOffset,
      'Overseer results',
      'Description of Overseer results',
      isViewportMobile,
    );
  
  
    // Draw all the links
    const linksGroup = modelSvg.append('g')
      .attr('id', 'links');
    setupRelationships(linksGroup, links);
  
    // Setup zooming and panning

    /*
    const zoomed = () => {
      nodesGroup.attr('transform', d3.event.transform);
      linksGroup.attr('transform', d3.event.transform);
      modelSvg.attr('transform', 'translateZ(0)');
    };
  
    const zoom = d3.zoom()
      .scaleExtent([1, 8])
      .on('zoom', zoomed);
  
    modelSvg.call(zoom)
      .on('wheel', () => { d3.event.preventDefault(); }) // Stop zooming from scrolling the entire window
      .on('dblclick.zoom', null);

  
    if (isViewportMobile) { // We initialse the model zoom into the farm inputs
      modelSvg.call(
        zoom.transform,
        d3.zoomIdentity
          .translate(MODEL_WIDTH / 2, 225)
          .scale(Math.min(8, 0.9 / Math.max(((MODEL_OFFSET + BOX_WIDTH) - MODEL_OFFSET) / MODEL_WIDTH, ((0 + BOX_HEIGHT) - 0) / MODEL_HEIGHT)))
          .translate(-(MODEL_OFFSET + (MODEL_OFFSET + BOX_WIDTH)) / 2, -(0 + (0 + BOX_HEIGHT)) * 0.66),
      );
    }

        */
  
    // Add event handlers
    addNodeEventHandlers(modelSvg, showItemDetails, links, null, isViewportMobile);
  }, [showItemDetails, isViewingModel, isViewportMobile]);
  
  return (
    <div className="u-mB-lg">
      <div>
        <section>
          <div className="Container">
            {(isViewportMobile && !isViewingModel) ? (
              <div className="ModelIntro">
                <h2 className="ModelIntro-title">Our scientific model</h2>
                <p className="ModelIntro-description">Gain a greater understanding of the scientific model that drives our OverseerFM</p>
                <img className="u-mT-sm u-mB-md" src={modelPreviewImage} alt="" />
                <Button
                  className="Button Button--primary"
                  label="Get Started"
                  onClick={handleViewingModel}
                />
              </div>
            ) : (
              <div className="ScientificModel">
                {!isViewportMobile && (
                <div className="u-textCenter">
                  <button
                    className="ScientificModel-overviewButton"
                    type="button"
                    onClick={() => showItemDetails({ cmsId: '6tFsbe8k0lGn85P75TyjkB' })}
                  >Read an overview
                  </button>
                </div>
                )}
                <div className="ScientificModel-wrapper" id="scientificModel">
                  <button
                    className="ScientificModel-exitButton"
                    type="button"
                    onClick={handleExitModel}
                  >
                    <span className="u-hiddenVisually">Exit model</span>
                  </button>
                  {!isViewportMobile && false && (
                    <div className={classnames('ScientificModel-zoomButtons u-print-hidden', {
                      'infoPanel-is-open': isInfoPanelOpen,
                    })}
                    >
                      <button
                        className="ScientificModel-zoomButtons-in" 
                        type="button"
                        //onClick={() => handleZoom(true)}
                      >
                        <span className="u-hiddenVisually">Zoom in</span>
                      </button>
                      <button
                        className="ScientificModel-zoomButtons-out"
                        type="button"
                        //onClick={() => handleZoom(false)}
                      >
                        <span className="u-hiddenVisually">Zoom out</span>
                      </button>
                    </div>
                  )}
                  <svg
                    id="scientific-model-svg"
                    className={isInfoPanelOpen ? 'u-print-hidden' : ''}
                    width="100%"
                    viewBox={`0 0 ${MODEL_WIDTH} ${isViewportMobile ? MODEL_HEIGHT_MOBILE : MODEL_HEIGHT}`}
                  >
                    <defs>
                      {['inputs', 'components', 'results'].map(category => (
                        <filter
                          key={`dropshadow-${category}`}
                          id={`dropshadow-${category}`}
                          y="-10%"
                          width="140%"
                          height="180%"
                          filterUnits="objectBoundingBox"
                          primitiveUnits="userSpaceOnUse"
                          colorInterpolationFilters="linearRGB"
                        >
                          <feOffset
                            dx="0"
                            dy="20"
                            in="SourceAlpha"
                            result="offset"
                          />
                          <feGaussianBlur
                            stdDeviation="12"
                            in="offset"
                            result="blur"
                          />
                          <feFlood
                            floodColor={DROP_SHADOW_COLOURS[category]}
                            floodOpacity="0.4"
                            result="composite"
                          />
                          <feComposite
                            in="flood"
                            in2="blur"
                            operator="in"
                            result="composite"
                          />
                          <feMerge
                            result="merge"
                          >
                            <feMergeNode
                              in="composite"
                              result="mergeNode"
                            />
                            <feMergeNode
                              in="SourceGraphic"
                              result="mergeNode1"
                            />
                          </feMerge>
                        </filter>
                      ))}
                    </defs>
                  </svg>
                  <InfoPanel
                    isOpen={isInfoPanelOpen}
                    data={scientificModelCurrentItem}
                    onPanelClose={handleInfoPanelClose}
                    isLoading={isScientificModelItemLoading}
                    itemLabelForError={selectedItemLabel}
                    itemIdForError={selectedItemId}
                  />
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {scientificModelCurrentItem && isInfoPanelOpen && (
        <div className="u-print u-print-breakBefore">
          <h2 className="IconList-heading h1">{scientificModelCurrentItem.title}</h2>
          {!!scientificModelCurrentItem.overview && (
            <RichText
              text={scientificModelCurrentItem.overview}
              options={RICH_TEXT_OVERRIDES}
            />
          )}
          {!!scientificModelCurrentItem.modelsAndComponents && (
            <RichText
              text={scientificModelCurrentItem.modelsAndComponents}
              options={RICH_TEXT_OVERRIDES}
            />
          )}
        </div>
      )}
    </div>
  );
};
