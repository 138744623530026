import * as d3 from 'd3';
import {
  COLUMN_MARGIN,
  BOX_WIDTH,
  BOX_HEIGHT,
  ROW_MARGIN,
  ITEM_ID_PREFIX,
  //MODEL_WIDTH,
  //MODEL_HEIGHT_MOBILE,
  MODEL_OFFSET,
} from './model';

const drawNodeInner = (container, options, data) => {
  const containerElement = container
    .append('foreignObject')
    .attr('width', BOX_WIDTH)
    .attr('height', BOX_HEIGHT)
    .attr('dy', 20)
    .attr('dx', 20)
    .attr('x', options.x)
    .attr('y', options.y)
    .append('xhtml:div')
    .attr('xmlns', 'http://www.w3.org/1999/xhtml');

  if (data.icon) {
    containerElement.append('xhtml:span').attr('class', `InputIcon InputIcon--${data.icon}`);
  }

  containerElement.append('xhtml:p').html(data.label);
};

export const drawNode = (
  rootElement,
  containerClass,
  options,
  data,
  columnGroupIndex,
  columnIndex,
  rowIndex,
  isViewportMobile,
) => {
  const containerPosition = {
    y:
      rowIndex * (ROW_MARGIN + BOX_HEIGHT)
      + (isViewportMobile ? 200 : 120)
      + (data.offset ? data.offset * (BOX_HEIGHT + ROW_MARGIN) : 0),
    x:
      (BOX_WIDTH + COLUMN_MARGIN) * columnGroupIndex
      + columnIndex * (BOX_WIDTH + COLUMN_MARGIN)
      + MODEL_OFFSET,
  };

  const rectangleContainerId = `${ITEM_ID_PREFIX}${data.id}`;
  const rectangleContainer = rootElement
    .append('g')
    .datum(data)
    .attr('id', rectangleContainerId)
    .attr('class', containerClass)
    .attr('x', containerPosition.x)
    .attr('y', containerPosition.y);

  const rectangle = rectangleContainer
    .append('rect')
    .attr('id', `rectangle-${data.id}`)
    .attr('class', options.className)
    .attr('width', options.width)
    .attr('height', options.height)
    .attr('stroke', options.stroke)
    .attr('fill', options.fill)
    .attr('x', containerPosition.x)
    .attr('y', containerPosition.y)
    .attr('rx', 8);

  if (data.label) {
    drawNodeInner(rectangleContainer, { ...containerPosition }, data);
  }

  return rectangle;
};

export const drawColumnGroup = (
  rootElement,
  containerClass,
  columnGroupData,
  rectangleOptions,
  columnStartIndex,
  headingText,
  descriptionText,
  isViewportMobile,
) => {
  columnGroupData.forEach((column, columnIndex) => {
    column.forEach((data, rowIndex) => {
      const containerPosition = {
        y: isViewportMobile ? 125 : 50,
        x:
            (BOX_WIDTH + COLUMN_MARGIN) * columnStartIndex
            + columnIndex * (BOX_WIDTH + COLUMN_MARGIN)
            + MODEL_OFFSET,
      };

      if (rowIndex === 0 && columnIndex === 0) {
        rootElement.append('text')
          .attr('class', 'ScientificModel-columnHeading')
          .attr('x', containerPosition.x)
          .attr('y', containerPosition.y)
          .text(headingText);
        rootElement.append('text')
          .attr('class', 'ScientificModel-columnDescription')
          .attr('x', containerPosition.x)
          .attr('y', containerPosition.y + 30)
          .text(descriptionText);
      }

      drawNode(
        rootElement,
        containerClass,
        rectangleOptions,
        data,
        columnStartIndex,
        columnIndex,
        rowIndex,
        isViewportMobile,
      );
    });
  });
};

const getLinePath = (
  coordinates,
  areNodesAdjacentSiblings,
  areNodesInSameColumn,
  isSourceNodeBelowTarget,
) => {
  const {
    x1, y1, x2, y2,
  } = coordinates;

  if (areNodesAdjacentSiblings || !areNodesInSameColumn) {
    return `M ${x1} ${y1} L ${x2} ${y2}`;
  }

  const curveFromSource = !isSourceNodeBelowTarget
    ? `c ${COLUMN_MARGIN / 6} 0, ${COLUMN_MARGIN / 2} 0, ${COLUMN_MARGIN / 2} ${BOX_HEIGHT / 2}`
    : `c ${COLUMN_MARGIN / 6} 0, ${COLUMN_MARGIN / 2} 0, ${COLUMN_MARGIN / 2} ${-BOX_HEIGHT / 2}`;
  const curveFromTarget = !isSourceNodeBelowTarget
    ? `c 0 ${BOX_HEIGHT / 4}, 0 ${BOX_HEIGHT / 2}, ${-COLUMN_MARGIN / 2} ${BOX_HEIGHT / 2}`
    : `c 0 ${-BOX_HEIGHT / 4}, 0 ${-BOX_HEIGHT / 2}, ${-COLUMN_MARGIN / 2} ${-BOX_HEIGHT / 2}`;

  return `
    M ${x1} ${y1}
    ${curveFromSource}
    L ${x1 + COLUMN_MARGIN / 2} ${y2
    + (!isSourceNodeBelowTarget ? -BOX_HEIGHT / 2 : BOX_HEIGHT / 2)}
    ${curveFromTarget}
  `;
};

/**
 * Calculates the coordinates for the line to be drawn between to connected nodes.
 * When two nodes are connected within the same column, the function calculates
 * the coordinates such that the connecting line displays in an appropriate place
 * e.g. not overlapping other nodes.
 * @param {*} link the link object
 */
const getLineCoordinates = (link) => {
  const { source, target } = link;

  const sourceNode = d3.select(`#box-container-${source}`);
  const sourceNodeX = parseFloat(sourceNode.attr('x'));
  const sourceNodeY = parseFloat(sourceNode.attr('y'));
  const sourceSibling = d3.select(`#box-container-${source} + g`);

  const targetNode = d3.select(`#box-container-${target}`);
  const targetNodeX = parseFloat(targetNode.attr('x'));
  const targetNodeY = parseFloat(targetNode.attr('y'));
  const targetSibling = d3.select(`#box-container-${target} + g`);

  const areNodesInSameColumn = sourceNodeX === targetNodeX;
  const areNodesAdjacentSiblings = areNodesInSameColumn
    && ((!sourceSibling.empty() && sourceSibling.attr('id')) === targetNode.attr('id')
      || (!targetSibling.empty() && targetSibling.attr('id')) === sourceNode.attr('id'));
  const isSourceNodeBelowTarget = sourceNodeY > targetNodeY;

  const x1 = areNodesAdjacentSiblings ? sourceNodeX + BOX_WIDTH / 2 : sourceNodeX + BOX_WIDTH;
  let y1 = 0;

  if (areNodesAdjacentSiblings) {
    y1 = isSourceNodeBelowTarget ? sourceNodeY : sourceNodeY + BOX_HEIGHT;
  } else {
    y1 = sourceNodeY + BOX_HEIGHT / 2;
  }

  let x2 = 0;

  if (areNodesInSameColumn) {
    x2 = areNodesAdjacentSiblings ? sourceNodeX + BOX_WIDTH / 2 : targetNodeX + BOX_WIDTH;
  } else {
    x2 = targetNodeX;
  }

  let y2 = 0;

  if (areNodesAdjacentSiblings) {
    y2 = isSourceNodeBelowTarget ? targetNodeY + BOX_HEIGHT : targetNodeY;
  } else {
    y2 = targetNodeY + BOX_HEIGHT / 2;
  }

  return {
    coordinates: {
      x1,
      y1,
      x2,
      y2,
    },
    linePath: getLinePath(
      {
        x1,
        y1,
        x2,
        y2,
      },
      areNodesAdjacentSiblings,
      areNodesInSameColumn,
      isSourceNodeBelowTarget,
    ),
  };
};

export const setupRelationships = (linksGroup, links) => {
  const linkElements = linksGroup
    .selectAll('line')
    .data(links)
    .enter()
    .append('g')
    .attr('class', 'Link')
    .attr('fill', '#d9d9d9')
    .attr('stroke', '#d9d9d9');

  linkElements.append('path').attr('d', (link) => {
    const { linePath } = getLineCoordinates(link);

    return linePath;
  });

  linkElements
    .append('circle')
    .attr('class', 'Link-source')
    .attr('cx', link => getLineCoordinates(link).coordinates.x1)
    .attr('cy', link => getLineCoordinates(link).coordinates.y1)
    .attr('r', '4.5');

  linkElements
    .append('circle')
    .attr('class', 'Link-target')
    .attr('cx', link => getLineCoordinates(link).coordinates.x2)
    .attr('cy', link => getLineCoordinates(link).coordinates.y2)
    .attr('r', '6');
};

/**
 * A method that recursively workouts all the neighbours of the selected node.
 * @param {*} nodeId the selected node's id
 * @param {*} links the links between nodes to check
 * @param {*} neighbours the current neighbours that have been calculated
 */
const getNeighbours = (sourceNode, nodeId, links, neighbours = [], getInputs, getOutputs) => {
  const filteredLinks = links.filter(l => l.source === nodeId || l.target === nodeId);

  neighbours.push(nodeId);

  filteredLinks.forEach((link) => {
    if (link.target === nodeId && getInputs) {
      neighbours.push(link.source);
      getNeighbours(sourceNode, link.source, links, neighbours, true, false);
    }

    if (link.source === nodeId && getOutputs) {
      neighbours.push(link.target);
      getNeighbours(sourceNode, link.target, links, neighbours, false, true);
    }
  });

  return neighbours;
};

const selectNode = (selectedNode, nodeElements, links, linkElements) => {
  const neighbours = getNeighbours(selectedNode.id, selectedNode.id, links, [], true, true);

  nodeElements
    .classed('is-selected', node => node.id === selectedNode.id)
    .classed('not-selected', node => !neighbours.includes(node.id) && node.id !== selectedNode.id);

  linkElements.classed(
    'not-selected',
    link => !neighbours.includes(link.target) || !neighbours.includes(link.source),
  );

  linkElements
    .attr('stroke', (link) => {
      if (neighbours.includes(link.source) && neighbours.includes(link.target)) {
        return nodeElements.select(`#rectangle-${link.source}`).attr('stroke');
      }

      return '#D9D9D9';
    })
    .attr('fill', (link) => {
      if (neighbours.includes(link.source) && neighbours.includes(link.target)) {
        return nodeElements.select(`#rectangle-${link.source}`).attr('stroke');
      }

      return '#D9D9D9';
    });
};

export const addNodeEventHandlers = (rootElement, onClick, links, zoom, isViewportMobile) => {
  const nodeElements = rootElement.select('#nodes').selectAll('g');
  const linkElements = rootElement.select('#links').selectAll('g');

  
  nodeElements.on('mousedown', (node) => {
    // We zoom and center the screen on the clicked node for mobile
    /*
    if (isViewportMobile) {
      d3.event.stopPropagation();
      const {
        x, y, width, height,
      } = d3
        .select(`#box-container-${node.id}`)
        .node()
        .getBBox();

      rootElement
        .transition()
        .duration(750)
        .call(
          zoom.transform,
          d3.zoomIdentity
            .translate(MODEL_WIDTH / 2, (MODEL_HEIGHT_MOBILE + y) / 2)
            .scale(
              Math.min(
                8,
                0.9
                  / Math.max((x + width - x) / MODEL_WIDTH, (y + height - y) / MODEL_HEIGHT_MOBILE),
              ),
            )
            .translate(-(x + (x + width)) / 2, -(y + (y + height)) * 0.60),
          d3.mouse(rootElement.node()),
        );
    }
    */

    onClick(node);
    selectNode(node, nodeElements, links, linkElements);
  });
};
