import { DebugActionTypes, TOGGLE_META } from './actions';

export interface IDebugState {
  shouldShowMeta: boolean;
}

export const initialState: IDebugState = {
  shouldShowMeta: false,
};

export const debugReducer = (state = initialState, action: DebugActionTypes) => {
  // const { payload } = action;

  switch (action.type) {
    case TOGGLE_META:
      return {
        ...state,
        shouldShowMeta: !state.shouldShowMeta,
      };
    default:
      return state;
  }
};
