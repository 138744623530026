import React, { createRef } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProps {
  isLoading: boolean;
}

/**
 * Provides accessible information about the page load status.
 * This should be added to all pages so that screen-readers can tell the user when the page
 * has loaded.
 *
 * For pages that load instantly (i.e. do not fetch CMS data), pass in `isLoading={false}`.
 *
 * Otherwise pass in the loading state, e.g `isLoading={isPageLoading}`.
 *
 * @param isLoading Whether or not the page is loading
 */
export class AccessibleLoadNotifier extends React.Component<IProps> {
  focusResetterEl = createRef<HTMLParagraphElement>();

  componentDidMount() {
    const {
      isLoading,
    } = this.props;

    // If the page is in a loaded state to start with, immediately say so
    if (this.focusResetterEl.current && isLoading === false) {
      this.focusResetterEl.current.focus();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { isLoading } = this.props;

    if (
      this.focusResetterEl.current
      && isLoading !== prevProps.isLoading
    ) {
      this.focusResetterEl.current.focus();
    }
  }

  render() {
    const { isLoading } = this.props;

    return (
      <>
        {isLoading ? (
          <p
            className="u-hiddenVisually"
            key="loading-msg"
            role="status"
            aria-live="polite"
            aria-atomic="true"
          >
            <FormattedMessage id="PAGE_LOADING" />
          </p>
        ) : (
          <p className="u-hiddenVisually" ref={this.focusResetterEl} tabIndex={-1} aria-hidden>
            <FormattedMessage id="PAGE_LOADED" />
          </p>
        )}
      </>
    );
  }
}
