import { connect } from 'react-redux';

import { actionGetPage } from 'redux/modules/cms/actions';
import { IState } from 'redux/modules';
import { page, isPageLoading } from 'redux/modules/cms/selectors';
import { isViewportSmall } from 'redux/modules/app/selectors';
import { NewsSummaryPage } from './NewsSummaryPage';

const mapStateToProps = (state: IState) => ({
  page: page(state),
  isPageLoading: isPageLoading(state),
  isViewportSmall: isViewportSmall(state),
});

const mapDispatchToProps = {
  getPage: actionGetPage,
};

export const NewsSummaryPageConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsSummaryPage);
