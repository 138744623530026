import ReactGA from 'react-ga';
import { ErrorInfo } from 'react';

import { parseError } from './util';

const GA_CATEGORIES = {
  Action: 'Action',
  Outbound: 'Outbound',
};

// Specific page tracking
export const trackPageView = (title: string) => {
  const {
    location: {
      pathname,
      search,
      hash,
    },
  } = document;

  const url = `${pathname}${search}${hash}`;
  const { REACT_APP_ENVIRONMENT, NODE_ENV } = process.env;

  ReactGA.set({
    page: url,
    environment: REACT_APP_ENVIRONMENT || NODE_ENV,
  });

  ReactGA.pageview(url, undefined, title);
};

// interface ITrackEvent {
//   type: string;
//   payload?: any;
// }

/**
 * trackEventReduxAction is used to track redux actions
 * This function could be used in the saga-monitor util for watching specific actions
 * @param action A Redux action
 */
// export const trackEventReduxAction = (action: ITrackEvent) => {
//   const { type, payload } = action;

//   if (!type) {
//     return;
//   }

//   const eventConfig = {
//     category: 'Action',
//     payloadAttributes: [
//       payload,
//     ],
//   };

//   if (!eventConfig) {
//     return;
//   }

//   ReactGA.event({
//     category: eventConfig.category,
//     action: resolveGaPayload(payload, eventConfig),
//     label: type,
//   });
// };

/**
 * trackEvent is a generic function useful for tracking events that aren't covered by the existing functions
 * E.G can be used to track click events, toggles, non redux actions
 * @param label Label of the action to be tracked
 * @param payload A string containing the information to be recorded by Google Analytics
 */
export const trackEvent = (label: string, payload: string) => {
  ReactGA.event({
    category: GA_CATEGORIES.Action,
    action: payload,
    label,
  });
}

// trackError is used as saga middleware to track saga errors
export const trackError = (type: Error, error: ErrorInfo) => ReactGA.ga('send', 'exception', {
  exDescription: parseError(type, error),
  exFatal: false,
});

export const trackExternalLink = (label: string, url: string) => {
  ReactGA.event({
    category: GA_CATEGORIES.Outbound,
    label,
    action: url,
  });
};

// export const trackTiming = (category, variable, value, label) => ReactGA.timing({
//   category,
//   variable,
//   value,
//   label,
// });
