import { Action } from 'redux';
import { SagaMonitor } from 'redux-saga';

import { CMSActionTypes, CMS } from 'redux/modules/cms/actions';
import { trackPageView } from 'tools/analytics/index';

export type ActionType = CMSActionTypes | Action;

const TRACKING = {
  [CMS.GET_PAGE_SUCCESS]: (action: ActionType) => {
    // Track each page success as pages are CMS driven
    // Pull out the meta title as this is needed for ReactGA

    // Return first entry in the data array as we're only expecting a single
    // page to be returned
    // @ts-ignore
    const data = action.payload.data[0];

    if (data) {
      const {
        metaTitle,
      } = data;
  
      trackPageView(metaTitle);
    }
  },
};

export const sagaMonitor: SagaMonitor = {
  // Fires on each redux effect resolving
  effectResolved(_, result) {
    // console.log('resolved effect', result);

    // Only care about action results that include a type, and is a field in the TRACKING object
    if (result.type && TRACKING[result.type]) {
      TRACKING[result.type](result);
    }
  },
};
