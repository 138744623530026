import React from 'react';

interface IEntryParagraph {
    title: string;
    description1: string;
    description2?: string;
}

interface IEntry {
    year: string;
    outcomes1?: string;
    outcomes2?: string;
    descriptions: Array<IEntryParagraph>;
    rightAlign: boolean;
}

interface ITimeline {
    id?: string;
    entries: Array<IEntry>;
}

export const Timeline = (props: ITimeline) => {
    const { id, entries = [] } = props;
    return (
        <section id={id}>
            <div className="Container">
                <div className="Timeline">
                    {entries.map((e,i)=>{
                        const {descriptions=[]}=e
                        const className = e.rightAlign ? "righty" : "lefty";
                        return(
                            <div key={i} className={`Timeline-entry ${className}`}>
                                <div className={`h2 Timeline-date ${className}`}>{e.year}</div>
                                <div className={`Timeline-content ${className}`}>
                                    {descriptions.map((d,x)=>(
                                    <div key={x} className="Timeline-text u-mB-sm">
                                            <div className='h1'>{d.title}</div>
                                            <div>{d.description1}</div>
                                            <div>{d.description2}</div>
                                    </div> 
                                    ))}
                                    {e.outcomes1 &&
                                    <div className="Timeline-text u-mT-md">
                                        <div className='h3'>Outcomes:</div>
                                        <div>{e.outcomes1}</div>
                                    </div>
                                    }
                                    {e.outcomes2 &&
                                    <div className="Timeline-text">{e.outcomes2}</div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};

