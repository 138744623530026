import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  IS_PRODUCTION,
  ENVIRONMENT,
  IS_DEVELOPMENT,
  APP_VERSION,
} from 'redux/modules/app/constants';

const ENV_CLASS = `is-${ENVIRONMENT}`;

interface IProps {
  shouldShowMeta: boolean;
  isViewportSmall: boolean;
  isViewportMedium: boolean;
  isViewportLarge: boolean;
  isViewportXLarge: boolean;
  isViewportXXLarge: boolean;
  toggleMeta: () => void;
}

export const SiteBanner = (props: IProps) => {
  const [isOpen, toggleOpen] = useState(false);

  const {
    shouldShowMeta,
    toggleMeta,
    isViewportSmall,
    isViewportMedium,
    isViewportLarge,
    isViewportXLarge,
    isViewportXXLarge,
  } = props;

  if (IS_PRODUCTION) {
    return null;
  }

  let currentBreakpoint = 'N/A';
  if (isViewportSmall) {
    currentBreakpoint = 'Small';
  }
  if (isViewportMedium) {
    currentBreakpoint = 'Medium';
  }
  if (isViewportLarge) {
    currentBreakpoint = 'Large';
  }
  if (isViewportXLarge) {
    currentBreakpoint = 'XL';
  }
  if (isViewportXXLarge) {
    currentBreakpoint = 'XXL';
  }

  return (
    <aside
      className={classNames('SiteBanner', ENV_CLASS, { 'is-open': isOpen })}
      aria-hidden
    >
      <button type="button" onClick={() => toggleOpen(!isOpen)} className="SiteBanner-toggle">
        <span aria-label="" role="img">
          🚧
        </span>
      </button>

      <div className="SiteBanner-content">
        <p className="small u-textBold u-mT-0 u-mB-0">
          <FormattedMessage id={IS_DEVELOPMENT ? 'DEV_ENV' : 'PRE_PROD_ENV'} />
          &nbsp;
          <FormattedMessage id="ENVIRONMENT" />
          &nbsp; - {APP_VERSION}
        </p>

        <label htmlFor="showMeta" className="u-mR-lg">
          Show meta&nbsp;
          <input id="showMeta" value={`${shouldShowMeta}`} type="checkbox" onChange={toggleMeta} />
        </label>

        <span className="u-mR-lg">
          Size:&nbsp;
          <strong>{currentBreakpoint}</strong>
        </span>

        <label htmlFor="girdCheckbox" className="u-mR-lg">
          Show grid&nbsp;
          <input
            id="girdCheckbox"
            type="checkbox"
            onChange={() => {
              // @ts-ignore
              document.querySelector('body').classList.toggle('u-debug-grid');
            }}
          />
        </label>
      </div>
    </aside>
  );
};
