import React from 'react';

import { RadioGroup } from 'views/components/RadioGroup';
import { Button } from 'views/components/Button/Button';
import { ICONS } from 'redux/modules/app/constants';
import { Icon } from 'views/components/Icon/Icon';
import { Loader } from 'views/components/Loader/Loader';
import { CmsImageConnected } from 'views/components/CmsImage/CmsImageConnected';

const SHOW_ICONS = true;
const OPTIONS = [
  {
    id: 'option-one',
    label: 'Option One',
    value: 'one',
  },
  {
    id: 'option-two',
    label: 'Option Two',
    value: 'two',
  },
  {
    id: 'option-three',
    label: 'Option Three',
    value: 'three',
  },
];

interface IState {
  [key: string]: string;
}

export class Preview extends React.Component<{}, IState> {
  state: IState = {};

  initElement = (el: HTMLHeadingElement | HTMLParagraphElement | null, tag?: string) => {
    if (!el) {
      return;
    }

    const tagName = tag || el.tagName;
    const stateKey = `${tagName}FontSize`;

    // eslint-disable-next-line react/destructuring-assignment
    const hasFontSize = this.state[stateKey];

    if (hasFontSize) {
      return;
    }

    // eslint-disable-next-line prefer-destructuring
    const fontSize = window.getComputedStyle(el).fontSize;

    // @ts-ignore
    this.setState({
      [stateKey]: fontSize,
    });
  }

  render() {
    const {
      H1FontSize,
      H2FontSize,
      H3FontSize,
      H4FontSize,
      H5FontSize,
      H6FontSize,
      PFontSize,
      PLeadFontSize,
      PSmallFontSize,
    } = this.state;

    return (
      <main className="Main">
        <div className="u-mL-lg u-mT-lg u-mB-lg u-mR-lg">
          <h1 ref={el => this.initElement(el)}>H1 - {H1FontSize} - lorem ipsum dolor sit amet</h1>
          <h2 ref={el => this.initElement(el)}>H2 - {H2FontSize} - lorem ipsum dolor sit amet</h2>
          <h3 ref={el => this.initElement(el)}>H3 - {H3FontSize} - lorem ipsum dolor sit amet</h3>
          <h4 ref={el => this.initElement(el)}>H4 - {H4FontSize} - lorem ipsum dolor sit amet</h4>
          <h5 ref={el => this.initElement(el)}>H5 - {H5FontSize} - lorem ipsum dolor sit amet</h5>
          <h6 ref={el => this.initElement(el)}>H6 - {H6FontSize} - lorem ipsum dolor sit amet</h6>
          <p ref={el => this.initElement(el, 'PLead')} className="lead">P lead - {PLeadFontSize} - lorem ipsum dolor sit amet 1234567890</p>

          <p ref={el => this.initElement(el)}>
            P body - {PFontSize} -
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec varius, ex nec
            sodales ultrices, odio nulla euismod nunc, a pharetra nisi ligula ac elit. Suspendisse
            scelerisque eu massa aliquet malesuada…
          </p>
          <p className="small" ref={el => this.initElement(el, 'PSmall')}>
            P small - {PSmallFontSize} -
            Body Small - Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec varius, ex nec
            sodales ultrices, odio nulla euismod nunc, a pharetra nisi ligula ac elit. Suspendisse
            scelerisque eu massa aliquet malesuada…
          </p>

          <hr />
          <br />
          <h3>Cms Image</h3>
          <div className="Preview-CmsImage">
            <CmsImageConnected
              mediaType="large"
              className="Preview-CmsImage-image"
              src="http://images.ctfassets.net/bo1h2c9cbxaf/3uWc330Mz0DYSg0qxqAAaq/60dab7df045d8799bb224f318400de17/img-homepage-hero_3x.png"
              alt="My test image"
              fit={{
                small: { w: 300, h: 254 },
                medium: { w: 552, h: 467 },
                large: { w: 800, h: 677 },
              }}
            />
          </div>

          <hr />
          <br />
          <h3>SVG Icons</h3>
          {SHOW_ICONS && (
            <div className="Preview-icons u-mB-xl">
              {/* @NOTE If you need icoMoon fonts then you'll need to loop through them here */}

              {Object.keys(ICONS.SVGs).map((iconName, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="u-block SVG SVG--md u-mL-lg u-mT-lg u-mR-lg u-mB-lg" key={`${iconName}-${idx}`}>
                  <Icon name={iconName} />
                  <p>{iconName}</p>
                </div>
              ))}
            </div>
          )}

          <hr />
          <br />
          <p>@NOTE This will not change because there is no component state</p>
          {/* @TODO Lilly said she would Storybook this */}
          <RadioGroup
            checkedValue="two"
            onChange={() => {}}
            name="MyRadioExample"
            options={OPTIONS}
          />

          <br />
          <h3>Button - Primary</h3>
          <Button onClick={() => {}} className="Button Button--primary" label="Normal" />
          <br />
          <br />
          <Button onClick={() => {}} className="Button Button--primary" label="Disabled" isDisabled />
          <br />
          <br />
          <Button onClick={() => {}} className="Button Button--primary" label="Loading" isLoading />
          <br />
          <br />
          <h3>Button - Primary with box shadow</h3>
          <Button
            onClick={() => {}}
            className="Button Button--primary Button--hasShadow"
            label="Normal"
          />
          <br />
          <br />
          <Button
            onClick={() => {}}
            className="Button Button--primary Button--hasShadow"
            label="Disabled"
            isDisabled
          />
          <br />
          <br />
          <Button
            onClick={() => {}}
            className="Button Button--primary Button--hasShadow"
            label="Loading"
            isLoading
          />
          <br />
          <br />
          <h3>Button - Secondary</h3>
          <Button onClick={() => {}} className="Button Button--secondary" label="Normal" />
          <br />
          <br />
          <Button
            onClick={() => {}}
            className="Button Button--secondary"
            label="Disabled"
            isDisabled
          />
          <br />
          <br />
          <Button onClick={() => {}} className="Button Button--secondary" label="Loading" isLoading />
          <br />
          <br />
          <h3>Button - Secondary with box shadow</h3>
          <Button
            onClick={() => {}}
            className="Button Button--secondary Button--hasShadow"
            label="Normal"
          />
          <br />
          <br />
          <Button
            onClick={() => {}}
            className="Button Button--secondary Button--hasShadow"
            label="Disabled"
            isDisabled
          />
          <br />
          <br />
          <Button
            onClick={() => {}}
            className="Button Button--secondary Button--hasShadow"
            label="Loading"
            isLoading
          />
          <br />
          <br />
          <h3>Button - Tertiary</h3>
          <span style={{ backgroundColor: 'lightGrey', padding: '10px', display: 'inline-block' }}>
            <Button onClick={() => {}} className="Button Button--tertiary" label="Normal" />
            <br />
            <br />
            <Button
              onClick={() => {}}
              className="Button Button--tertiary"
              label="Disabled"
              isDisabled
            />
            <br />
            <br />
            <Button
              onClick={() => {}}
              className="Button Button--tertiary"
              label="Loading"
              isLoading
            />
          </span>
          <h3>Button - Tertiary with box shadow</h3>
          <span style={{ backgroundColor: 'lightGrey', padding: '10px', display: 'inline-block' }}>
            <Button
              onClick={() => {}}
              className="Button Button--tertiary Button--hasShadow"
              label="Normal"
            />
            <br />
            <br />
            <Button
              onClick={() => {}}
              className="Button Button--tertiary Button--hasShadow"
              label="Disabled"
              isDisabled
            />
            <br />
            <br />
            <Button
              onClick={() => {}}
              className="Button Button--tertiary Button--hasShadow"
              label="Loading"
              isLoading
            />
          </span>
          <br />
          <br />

          <h3>Open email client button</h3>
          {/* ?subject=Subject&body=message%20goes%20here */}
          <a href="mailto:mail@example.org" className="Button Button--primary">Open mail client</a>

          <h3>Loader</h3>
          <Loader label="Loading" />

        </div>
      </main>
    );
  }
}
