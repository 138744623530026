// Exports all actions for the module
import types from './types';

export const showGlobalHeader = () => ({
  type: types.SHOW_GLOBAL_HEADER,
});

export const hideGlobalHeader = () => ({
  type: types.HIDE_GLOBAL_HEADER,
});

export const showGlobalFooter = () => ({
  type: types.SHOW_GLOBAL_FOOTER,
});

export const hideGlobalFooter = () => ({
  type: types.HIDE_GLOBAL_FOOTER,
});
