import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { OVERSEER_URLS, GROUPED_NAVIGATION } from '../../../redux/modules/app/constants';

import logo from '../../../images/overseer-logo-white@2x.png';

import { Button } from '../Button/Button';
import { SubscribeToNewsletter } from '../SubscribeToNewsletter/SubscribeToNewsletter';

export const Footer = () => {
  const isFooterHidden = useSelector(state => state.app.isGlobalFooterHidden);

  if (isFooterHidden) { return null; }

  return (
    <footer className="Footer u-print-hidden">
      <div className="Container">
        <div className="Grid">
          <h2 className="u-hiddenVisually">
            <FormattedMessage id="SITE_INFORMATION" />
          </h2>

          <div className="Footer-CTA Grid-cell">
            <div className="Grid-cell u-width1of1 u-md-width5of6 u-lg-width1of2">
              <h2 className="h1 u-mB-md">
                <FormattedMessage id="OVERSEERFM_IS_USED_BY" />
              </h2>
            </div>
            <div className="Grid-cell u-md-before1of6 u-md-width2of6  u-textCenter u-md-textRight">
              <Button
                className="Button Button--primary"
                isLabelIntl
                label="SIGN_UP_NOW"
                isLinkButton
                url={OVERSEER_URLS.PORTAL_SIGN_UP}
              />
            </div>
          </div>

          <hr />

          <div className="Footer-content Grid-cell">
            <div className="Grid">
              {Object.keys(GROUPED_NAVIGATION).map(section => (
                <div key={`Footer-nav-${section}`} className="Footer-nav Grid-cell u-md-width1of6">
                  <span className="Footer-subheading">
                    <FormattedMessage id={section} />
                  </span>

                  <ul className="Footer-list">
                    {GROUPED_NAVIGATION[section].map(page => (
                      <li key={`Footer-item-${page.name}`} className="Footer-item u-pT-xs u-pB-xs">
                        <Button
                          className=""
                          isLinkButton
                          isRoute={page.isRoute}
                          isLabelIntl
                          label={page.name}
                          shouldOpenNewTab={page.shouldOpenNewTab || false}
                          url={page.url}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              ))}

              <div className="Grid-cell u-md-width4of6">
                <span className="Footer-subheading">
                  <FormattedMessage id="STAY_UP_TO_DATE" />
                </span>
                <p className="SubscribeToNewsletter-description">
                  <FormattedMessage id="SUBSCRIBE_TO_OUR_NEWSLETTER" />
                </p>
                <SubscribeToNewsletter />
              </div>
            </div>
          </div>

          <div className="Footer-utilities Grid-cell">
            <div className="Footer-socialLinks  u-md-before1of6 u-md-mR-md">
              <span className="u-hiddenVisually">
                <FormattedMessage id="SOCIAL_MEDIA_LINKS" />
              </span>
              <ul className="Footer-list u-flex">
                <li className="Footer-item Footer-item--facebook">
                  <a href={OVERSEER_URLS.FACEBOOK} target="_blank" rel="noopener noreferrer">
                    <span className="u-hiddenVisually">
                      <FormattedMessage id="FACEBOOK" />
                    </span>
                  </a>
                </li>
                <li className="Footer-item Footer-item--twitter">
                  <a href={OVERSEER_URLS.TWITTER} target="_blank" rel="noopener noreferrer">
                    <span className="u-hiddenVisually">
                      <FormattedMessage id="TWITTER" />
                    </span>
                  </a>
                </li>
                <li className="Footer-item Footer-item--youtube">
                  <a href={OVERSEER_URLS.YOUTUBE} target="_blank" rel="noopener noreferrer">
                    <span className="u-hiddenVisually">
                      <FormattedMessage id="YOUTUBE" />
                    </span>
                  </a>
                </li>
                <li className="Footer-item Footer-item--linkedIn">
                  <a href={OVERSEER_URLS.LINKEDIN} target="_blank" rel="noopener noreferrer">
                    <span className="u-hiddenVisually">
                      <FormattedMessage id="LINKEDIN" />
                    </span>
                  </a>
                </li>

              </ul>
            </div>

            <div className="Footer-logo u-md-width1of6">
              <img src={logo} alt="Logo" />
            </div>

            <p className="Footer-disclaimer fineprint">
              <FormattedMessage id="FOOTER_DISCLAIMER" values={{ year: new Date().getFullYear(), version: process.env.REACT_APP_VERSION }} />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
