import animalDistribution from './animal-distribution.svg';
import animals from './animals.svg';
import climate from './climate.svg';
import crops from './crops.svg';
import drainage from './drainage.svg';
import effluentSystems from './effluent-systems.svg';
import fertiliser from './fertiliser.svg';
import fruit from './fruit.svg';
import irrigation from './irrigation.svg';
import pasture from './pasture.svg';
import soil from './soil.svg';
import soilTests from './soil-tests.svg';
import supplements from './supplements.svg';

export const ICONS = {
  animalDistribution,
  animals,
  climate,
  crops,
  drainage,
  effluentSystems,
  fertiliser,
  fruit,
  irrigation,
  pasture,
  soil,
  soilTests,
  supplements,
};
