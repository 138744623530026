import React from 'react';

type Fit = {
  w: number;
  h: number;
}

interface IFit {
  small?: Fit;
  medium?: Fit;
  large?: Fit;
  xLarge?: Fit;
  xxLarge?: Fit;
}

interface IProps {
  alt: string;
  className?: string;
  src: string;
  fit?: IFit;
  mediaType: keyof IFit;
}

function getFit(fit: IFit, mediaType: keyof IFit): Fit | null {
  const hasFit = fit[mediaType];

  // If we have a fit for that size then return that
  if (hasFit) {
    return hasFit;
  }

  // Switch statement to try and fetch the next size up of the image
  // Best case we load the image at the next size up
  // Worst case we load the original image (null)
  switch (mediaType) {
    case 'small':
      return fit.medium || fit.large || fit.xLarge || fit.xxLarge || null;
    case 'medium':
      return fit.large || fit.xLarge || fit.xxLarge || null;
    case 'large':
      return fit.xLarge || fit.xxLarge || null;
    case 'xLarge':
      return fit.xxLarge || null;
    default:
      return null;
  }
}

// Contentful has a max image size of 4000x4000
// If an image is requested that is greater than this it will return a server error
// This could occur on TVs which will have extremely large images
const MAX_CMS_SIZE = 4000;

/**
 * CmsImage is used for requesting CMS images at specific sizes based on the current breakpoint
 *
 * NOTE: If a breakpoint isn't set, it will try and use the next largest image size available.
 * This means, if both your small and medium image widths and heights are the same, then you
 * can just supply the medium breakpoint fit size - see example below
 *
 * ```
  <CmsImageConnected
    className="MyExample"
    src={image.file.url}
    alt={image.description || ''}
    fit={{
      // small: { w: 50, h: 50 }, // Don't need small as it's covered by medium
      medium: { w: 50, h: 50 },
      large: { w: 250, h: 250 },
      // xLarge: { w: 500, h: 500 }, // Don't need xLarge as it's covered by xxLarge
      xxLarge: { w: 500, h: 500 },
    }}
  />
 * ```
 */
export const CmsImage = (props: IProps) => {
  const {
    alt,
    className,
    src,
    fit,
    mediaType,
  } = props;

  let currentSrc = src;

  // If a fit object is provided and we have a mediaType
  // then attempt to get the fit for the current breakpoint
  if (fit && mediaType) {
    const hasFit = getFit(fit, mediaType);

    if (hasFit) {
      const { devicePixelRatio } = window;

      const { w, h } = hasFit;

      let width: string | number = Math.round(w * devicePixelRatio);
      let height: string | number = Math.round(h * devicePixelRatio);

      // Setting the width and height query to a blank string will request the original image
      if (width > MAX_CMS_SIZE) {
        width = '';
      }

      if (height > MAX_CMS_SIZE) {
        height = '';
      }

      currentSrc = `${src}?w=${width}&h=${height}`;
    }
  }

  return (
    <img className={className} src={currentSrc} alt={alt} />
  );
};
