import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { Loader } from '../Loader/Loader';
import { Icon } from '../Icon/Icon';
import { trackExternalLink } from '../../../tools/analytics';
import { isRouteURL } from '../../../tools/utilities/isRouteUrl';

/**
 * Button Component, configurable to produce a <button>,
 * <Link> or <a> tag.
 */
class ButtonClass extends Component {
  handleClick = (proxy) => {
    const {
      isLinkButton, url, onClick, shouldOpenNewTab
    } = this.props;

    if (shouldOpenNewTab) {
      window.open(url, '_blank');
      window.location = '/';
    }

    /*
     * For the case when a link needs rendered and no
     * url is assigned.
     */
    if (isLinkButton && !url && !isRouteURL(url)) {
      proxy.preventDefault();
    }

    if (typeof onClick === 'function') {
      onClick();
    }
  };

  handleBlur = () => {
    const { onBlur } = this.props;

    if (typeof onBlur === 'function') {
      onBlur();
    }
  }

  render() {
    const {
      buttonType,
      className,
      disabledClass,
      icon,
      // iconClass,
      isIconOnly,
      id,
      intl,
      isDisabled,
      isFauxDisabled,
      isLinkButton,
      isLoading,
      isRoute,
      label,
      hiddenLabel,
      shouldOpenNewTab,
      isLabelIntl,
      role,
      title,
      // wrapperClass,
      url,
    } = this.props;
    const target = shouldOpenNewTab ? '_blank' : '';
    const hasButtonClass = className.includes('Button');
    const labelClassName = classnames('u-mT-0 u-mB-0', {
      'Button-text': hasButtonClass,
      'u-hiddenVisually': isIconOnly,
    });

    // setup the common props for all variations
    const setupProps = {
      className,
      role,
      id,
      onClick: this.handleClick,
      title,
    };

    const labelFormatted = isLabelIntl ? intl.formatMessage({ id: label }) : label;

    const innerContent = (
      <Fragment>
        {icon && <Icon name={icon} className={`icon icon-${icon}`} />}
        <span className={labelClassName} aria-hidden>
          {labelFormatted}
        </span>

        {/* This is the hidden label for screen readers */}
        <span className="u-hiddenVisually">
          {hiddenLabel || labelFormatted}
        </span>
      </Fragment>
    );

    if (isLinkButton && (isRoute || isRouteURL(url))) {
      return (
        <Link {...setupProps} to={url} role={role}>
          {innerContent}
        </Link>
      );
    }

    // External link
    if (isLinkButton && !isRouteURL(url)) {
      return (
        <a {...setupProps} href={url} target={target} rel="noopener noreferrer" onClick={() => trackExternalLink(labelFormatted, url)}>
          {innerContent}
        </a>
      );
    }

    return (
      // eslint-disable-next-line
      <button
        type={buttonType}
        className={classnames(className, {
          [disabledClass]: isDisabled,
          [disabledClass]: isFauxDisabled,
          'is-loading': isLoading,
          'has-icon': icon,
        })}
        onClick={this.handleClick}
        onBlur={this.handleBlur}
        disabled={isDisabled}
        title={title}
        role={role}
        id={id}
      >
        {isLoading && (
          <div>
            <Loader />
            <span className={`${labelClassName} u-hiddenVisually`}>
              {labelFormatted}
            </span>
          </div>
        )}

        {!isLoading && innerContent}
      </button>
    );
  }
}

export const Button = injectIntl(ButtonClass);

ButtonClass.propTypes = {
  buttonType: PropTypes.string,
  className: PropTypes.string,
  disabledClass: PropTypes.string,
  hiddenLabel: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  id: PropTypes.string,
  intl: intlShape.isRequired,
  isDisabled: PropTypes.bool,
  isFauxDisabled: PropTypes.bool,
  isIconOnly: PropTypes.bool,
  isLabelIntl: PropTypes.bool,
  isLinkButton: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRoute: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  role: PropTypes.string,
  shouldOpenNewTab: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  wrapperClass: PropTypes.string,
};

ButtonClass.defaultProps = {
  buttonType: 'Submit',
  className: '',
  disabledClass: 'Button is-disabled',
  icon: '',
  iconClass: null,
  id: null,
  isDisabled: false,
  isFauxDisabled: false,
  isIconOnly: false,
  isLinkButton: false,
  isLoading: false,
  isLabelIntl: false,
  isRoute: false,
  label: '',
  onBlur: null,
  onClick: null,
  role: null,
  shouldOpenNewTab: false,
  title: '',
  url: '',
  wrapperClass: '',
  hiddenLabel: '',
};
