import { CMSActionTypes, CMS, IScientificModelItem, ICmsFail } from 'redux/modules/cms/actions';

export interface ICmsData {
  data: any[];
  includes: any;
}

/*
 * `data` is the actual record that was requested by the action.
 * `includes` contains the records linked to what was requested.
 */
// const CMS_DATA_BOILERPLATE: ICmsData = {
//   data: [],
//   includes: {},
// };

export interface ICmsState {
  page: {
    currentPage: string;
    isLoading: boolean;
  };
  scientificModel: {
    currentItem: null | {
      data: IScientificModelItem[];
    }
    cachedItems: {
      [keyof: string]: IScientificModelItem;
    };
    isLoading: boolean;
    error?: any;
  }
}

export const initialState: ICmsState = {
  page: {
    currentPage: '',
    isLoading: true,
    // Example page entry
    // home: { data: [], includes {} }
  },
  scientificModel: {
    currentItem: null,
    cachedItems: {},
    isLoading: false,
  },
};

export const cmsReducer = (state = initialState, action: CMSActionTypes) => {
  const { payload } = action;

  switch (action.type) {
    case CMS.GET_PAGE:
      return {
        ...state,
        page: {
          ...state.page,
          isLoading: true,
        },
      };
    case CMS.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: {
          ...state.page,
          currentPage: payload.id,
          [payload.id]: {
            ...payload,
          },
          isLoading: false,
        },
      };
    case CMS.GET_PAGE_FAIL:
      return {
        ...state,
        page: {
          ...state.page,
          [payload.id]: {
            ...payload,
          },
          isLoading: false,
        },
      };
    case CMS.GET_SCIENTIFIC_MODEL_ITEM:
      return {
        ...state,
        scientificModel: {
          ...state.scientificModel,
          isLoading: true,
        },
      };
    case CMS.GET_SCIENTIFIC_MODEL_ITEM_SUCCESS:
      return {
        ...state,
        scientificModel: {
          ...state.scientificModel,
          currentItem: payload,
          cachedItems: {
            [payload.id]: {
              ...payload,
            },
          },
          isLoading: false,
        },
      };
    case CMS.GET_SCIENTIFIC_MODEL_ITEM_FAIL:
      // eslint-disable-next-line no-case-declarations
      const errorPayload = (action as ICmsFail).payload;

      return {
        ...state,
        scientificModel: {
          ...state.scientificModel,
          isLoading: false,
          error: errorPayload.error,
        },
      };
    default:
      return state;
  }
};
