import React, { useState } from 'react';

const AkuakuContext = React.createContext([{}, () => {}]);

const AkuakuProvider = (props) => {
  const [context, setContext] = useState({});
  return (
    <AkuakuContext.Provider value={[context, setContext]}>
      {props.children}
    </AkuakuContext.Provider>
  );
};

export { AkuakuContext, AkuakuProvider }