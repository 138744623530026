import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { CmsPageConnected } from 'views/components/CmsPage/CmsPageConnected';
import { RenderModules } from 'views/components/RenderModules/RenderModules';

type TParams = {cmsPageId:string}


interface IProps extends RouteComponentProps<TParams> {
  defaultPageId : string
}

const WrapperPage = (props: IProps) => {
  const { match, defaultPageId } = props;
  const { params } = match;
  const { cmsPageId = defaultPageId } = params;

  return (
    <main className={classNames('Main', { [`Main--${cmsPageId}`]: cmsPageId })}>
      <CmsPageConnected
        cmsPageId={cmsPageId}
        component={(pageProps: any) => <RenderModules {...pageProps} cmsPageId={cmsPageId} />}
      />
    </main>
  );
};
export const Wrapper = withRouter(WrapperPage);
