import { connect } from 'react-redux';

import { actionToggleMeta } from '../../../redux/modules/debug/actions';
import { SiteBanner } from './SiteBanner';
import { IState } from '../../../redux/modules';
import { shouldShowMeta } from '../../../redux/modules/debug/selectors';
import {
  isViewportSmall,
  isViewportMedium,
  isViewportLarge,
  isViewportXLarge,
  isViewportXXLarge,
} from '../../../redux/modules/app/selectors';

const mapStateToProps = (state: IState) => ({
  shouldShowMeta: shouldShowMeta(state),
  isViewportSmall: isViewportSmall(state),
  isViewportMedium: isViewportMedium(state),
  isViewportLarge: isViewportLarge(state),
  isViewportXLarge: isViewportXLarge(state),
  isViewportXXLarge: isViewportXXLarge(state),
});

const mapDispatchToProps = {
  toggleMeta: actionToggleMeta,
};

export const SiteBannerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteBanner);
