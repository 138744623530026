import React from 'react';

import { Videos } from './Videos/Videos';
import { Resources } from './Resources/Resources';

interface IResource {
  text: string;
  title: string;
  resourceUrl: string;
  timeToRead: string;
  tags: string[];
}

interface IProps {
  items: IResource[];
  title: string;
  displayVariation?: string;
  tagClassName?: string;
}

export const VideosAndResources = ({ displayVariation, ...props }: any) => {
  if (displayVariation === 'Resources') {
    return <Resources {...props} />;
  }

  if (displayVariation === 'Videos') {
    return <Videos {...props} />;
  }

  return null;
};
