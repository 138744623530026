import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import { Button } from 'views/components/Button/Button';

interface ITooltipProps {
  title: string;
  text: string;
  label: string;
}

export const Tooltip = ({ title, text, label }: ITooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef(null);

  const toolTipClasses = classnames('Tooltip-content', { 'is-open': isOpen });

  // Close the Tooltip when the user clicks outside it
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef]);

  return (
    <div className="Tooltip" aria-haspopup="true">
      <span
        tabIndex={0}
        className="Tooltip-label"
        role="button"
        onClick={() => setIsOpen(!isOpen)}
        onKeyUp={() => setIsOpen(!isOpen)}
      >
        {label}
      </span>
      <div ref={tooltipRef} role="tooltip" className={toolTipClasses} aria-hidden>
        <span className="Tooltip-content-title">{title}</span>
        <span className="Tooltip-content-description">{text}</span>
        <Button className="Button Button--primary" label="OK, got it" onClick={() => setIsOpen(false)} />
      </div>
    </div>
  );
};
