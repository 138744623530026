import React from 'react';
import classNames from 'classnames';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Icon } from 'views/components/Icon/Icon';

interface IIcon {
  iconName: string;
  title: string;
}

interface IProps {
  icons: IIcon[];
  title: string;
}

// They wrap and are left aligned in small or if there is an odd number in medium+.
// If there is an even number in medium+ they are centred.
export const BigList = ({ icons, title }: IProps) => (
  <section className={classNames(
    'BigList ModuleWrapper',
    {
      'BigList--odd': icons.length % 2 === 1,
    },
  )}
  >
    <ComponentInfoConnected zeplinId="BIGLIST01-01a" shouldShowMeta={false}/>
    <div className="Container">
      <h2 className="h1 BigList-title u-mT-0 u-textCenter">{title}</h2>

      <ul className="Grid BigList-list">
        {icons.map(({ iconName, title: iconTitle }) => {
          if (!iconName || !iconTitle) {
            return null;
          }

          return (
            <li key={`${iconName}-${iconTitle}`} className="BigList-item-gridItem Grid-cell u-width1of4 u-md-width1of6">
              <div className="BigList-item">
                <div className="BigList-item-icon">
                  <Icon name={iconName} className="SVG" />
                </div>

                <p className="BigList-item-text">{iconTitle}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  </section>
);
