import { connect } from 'react-redux';

import { IState } from 'redux/modules';
import {
  isViewportSmall,
  isViewportMedium,
  isViewportLarge,
  isViewportXLarge,
  // isViewportXXLarge,
} from 'redux/modules/app/selectors';

const renderFunctionOrComponent = (component: React.ReactNode) => {
  if (typeof component === 'function') {
    return component();
  }
  return component;
};

interface IProps {
  small?: React.ReactNode;
  medium?: React.ReactNode;
  large?: React.ReactNode;
  xLarge?: React.ReactNode;
  xxLarge?: React.ReactNode;

  isViewportSmall?: boolean;
  isViewportMedium?: boolean;
  isViewportLarge?: boolean;
  isViewportXLarge?: boolean;
  // isViewportXXLarge: boolean;
}

/*
// Example Usage:
export const ExampleComponent = () => {
  return (
    <div className="ExampleComponent">
      <LargieSmalls
        small={(
          <p>This text will only appear at the small breakpoint</p>
        )}
        medium={(
          <p>This text will only appear at the medium breakpoint</p>
        )}
      />
    </div>
  );
}
*/

/**
 * LargieSmalls is used to swap components when a breakpoint changes.
 * Most of the swapping occurs between the small and medium breakpoints.
 * Hence why only these two breakpoints have been enabled.
 */
export const LargieSmallsComponent = (props: IProps) => {
  const {
    small,
    medium,
    large,
    xLarge,
    xxLarge,
    isViewportSmall: viewportSmall,
    isViewportMedium: viewportMedium,
    isViewportLarge: viewportLarge,
    isViewportXLarge: viewportXLarge,
    // isViewportXXLarge,
  } = props;

  if (small && viewportSmall) {
    return renderFunctionOrComponent(small);
  }

  if (medium && viewportMedium) {
    return renderFunctionOrComponent(medium);
  }

  // Fallback to mobile if it exists
  if (!medium && viewportMedium && small) {
    return renderFunctionOrComponent(small);
  }

  // Large
  if (large && viewportLarge) {
    return renderFunctionOrComponent(large);
  }

  // Large fallback - tablet or mobile
  if (!large && viewportLarge && (medium || small)) {
    return renderFunctionOrComponent(medium || small);
  }

  // xLarge;
  if (xLarge && viewportXLarge) {
    return renderFunctionOrComponent(xLarge);
  }

  // xLarge fallback - large or tablet or mobile
  if (!xLarge && viewportXLarge && (large || medium || small)) {
    return renderFunctionOrComponent(large || medium || small);
  }

  // xxLarge
  // Final fallback: xxLarge - xLarge - large - medium - small
  return (
    renderFunctionOrComponent(xxLarge)
    || renderFunctionOrComponent(xLarge)
    || renderFunctionOrComponent(large)
    || renderFunctionOrComponent(medium)
    || renderFunctionOrComponent(small)
  );
};

const mapStateToProps = (state: IState) => ({
  isViewportSmall: isViewportSmall(state),
  isViewportMedium: isViewportMedium(state),
  isViewportLarge: isViewportLarge(state),
  isViewportXLarge: isViewportXLarge(state),
  // isViewportXXLarge: isViewportXXLarge(state),
});

export const LargieSmalls = connect(
  mapStateToProps,
  null,
)(LargieSmallsComponent);
