import React from 'react';
import classNames from 'classnames';

import { Swiper } from 'views/components/Swiper/Swiper';
import { LargieSmalls } from 'views/components/LargieSmalls/LargieSmalls';
import { Button } from 'views/components/Button/Button';
import { WithFilter } from 'views/enhancers/WithFilter/WithFilter';
import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { IMedia } from 'types';

interface IResource {
  buttonLabel?: string;
  estimatedTime?: string;
  buttonLink?: string;
  tags: string[];
  text: string;
  title: string;
  mediaLink?: IMedia;
}

interface IProps {
  filters: string[];
  idSlug?: string;
  items: IResource[];
  itemsToDisplay: IResource[];
  title: string;
  hideFilters?: boolean;
}

const ResourceSlider = ({ itemsToDisplay }: IProps) => {
  const renderItem = ({
    title,
    estimatedTime,
    text,
    buttonLabel,
    buttonLink,
    mediaLink,
  }: IResource) => (
    <div key={title} className="Resources-item Grid-cell u-width1of1">
      <div className="Resources-content">
        <div className="Resources-details">
          {estimatedTime && <small className="u-block">{estimatedTime}</small>}
          <strong>{title}</strong>
          <p className="u-mB-0 u-mT-0">{text}</p>
        </div>

        <div className="Resources-button">
          {buttonLabel && buttonLink && (
            <Button
              className="Button Button--secondary"
              isLinkButton
              label={buttonLabel}
              hiddenLabel={`${buttonLabel}, ${title}`}
              url={buttonLink}
            />
          )}
          {buttonLabel && !buttonLink && mediaLink && (
            <Button
              className="Button Button--secondary"
              isLinkButton
              label={buttonLabel}
              hiddenLabel={`${buttonLabel}, ${title}`}
              url={mediaLink.file.url}
              shouldOpenNewTab
            />
          )}
        </div>
      </div>
    </div>
  );

  const items = itemsToDisplay.map((resource: IResource) => renderItem(resource));

  const renderSwiper = (size: string, slidesPerView: number, hasButtons: boolean) => (
    <div key={size} className={classNames('Resources-carousel', { Grid: size === 'small' })}>
      <div className="Resources-boxShadow">
        <Swiper
          items={items}
          hasButtons={hasButtons}
          options={{
            shouldSwiperUpdate: true,
            slidesPerView,
          }}
        />
      </div>
    </div>
  );

  return (
    <div className="Grid-cell Grid-cell--center">
      
      <LargieSmalls 
        small={renderSwiper('small', 1, false)} 
        medium={renderSwiper('med', 3, true)}
      />
      
    </div>
  );
};

const ResourceSliderWithFilter = WithFilter(ResourceSlider);

export const Resources = (props: IProps) => {
  const {
    hideFilters,
    title,
    idSlug,
    items,
  } = props;

  return (
    <div id={idSlug} className="Resources ModuleWrapper">
      <ComponentInfoConnected zeplinId={`RES01-01${hideFilters ? 'c' : 'a'}`} shouldShowMeta={false}/>
      <div className="Container">
        <div className="Grid">
          <div className="Grid-cell u-width1of1 u-textCenter">
            <h2 className="h1 u-mB-md u-mT-0">{title}</h2>

            {/* Don't show the tag filters so just pass through all items as itemsToDisplay */}
            {hideFilters ? (
              <ResourceSlider {...props} title={title} itemsToDisplay={items} />
            ) : (
              <ResourceSliderWithFilter {...props} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
