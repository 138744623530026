export const TOGGLE_META = 'DEBUG_TOGGLE_META';

export interface IToggleMeta {
  type: typeof TOGGLE_META;
  payload: null;
}

export type DebugActionTypes = IToggleMeta;

export function actionToggleMeta(): DebugActionTypes {
  return {
    type: TOGGLE_META,
    payload: null,
  };
}
