import React, { Fragment } from 'react';
import classnames from 'classnames';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Icon } from 'views/components/Icon/Icon';
import { Button } from 'views/components/Button/Button';

type DISPLAY_VARIATION = 'Steps' | 'Ticks';

interface IItem {
  title: string;
  text: string;
}

interface IProps {
  buttonLabel: string;
  buttonLink: string;
  iconName: string;
  items: IItem[];
  subheading: string;
  text: string;
  displayVariation: DISPLAY_VARIATION;
}

export const Callout = (props: IProps) => {
  const {
    text,
    iconName,
    items,
    buttonLabel,
    buttonLink,
    subheading,
    displayVariation = 'Steps',
  } = props;

  const formattedVariation = displayVariation.toLowerCase();
  const shouldShowList = items && items.length > 0;

  let zeplinId = 'CALLOUT01-01';
  if (!shouldShowList) {
    zeplinId += 'a';
  } else {
    const VARIATION_TO_ZEPLIN_ID = {
      Steps: 'b',
      Ticks: 'c',
    };

    zeplinId += VARIATION_TO_ZEPLIN_ID[displayVariation];
  }

  const wrapperClassNames = classnames('Callout ModuleWrapper', {
    [`Callout--${formattedVariation}`]: displayVariation && shouldShowList,
  });

  const isStepVariation = displayVariation === 'Steps' && shouldShowList;
  const isTickVariation = displayVariation === 'Ticks' && shouldShowList;

  return (
    <section className={wrapperClassNames}>
      <ComponentInfoConnected zeplinId={zeplinId} shouldShowMeta={false}/>

      <div className="Container">
        <div className="Callout-content Grid">
          <Icon name={iconName} className="Callout-icon SVG SVG--md" aria-hidden />

          <div className="Grid-cell u-width1of1 u-md-width10of12">
            <h2 className="h1 u-textCenter">{text}</h2>
          </div>
        </div>

        {items && (
          <div className="Callout-wrapper Grid">
            <hr />

            <div className="Grid-cell u-width1of1">
              <h3 className="p u-mT-0 u-textCenter u-mB-md">{subheading}</h3>

              <ul className="Callout-list Grid">
                {items.map(({ title, text: itemText }, index) => (
                  <li
                    // eslint-disable-next-line react/no-array-index-key
                    key={`Callout-item-${title}${index}`}
                    className={`Callout-item Grid-cell u-width1of1 u-md-width1of${items.length}`}
                  >
                    <div className="Callout-content u-textCenter">
                      <div className="Callout-icon">
                        {isStepVariation && (
                          <Fragment>
                            <span className="u-hiddenVisually">Step </span>
                            {index + 1}
                          </Fragment>
                        )}

                        {isTickVariation && <Icon name="green-tick" className="SVG--sm" />}
                      </div>
                      <h4 className="u-textMedium">{title}</h4>

                      <p className="u-mB-0 u-mT-xs">{itemText}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {buttonLink && buttonLabel && (
              <div className="u-textCenter">
                <Button
                  className="Callout-button Button"
                  isLinkButton
                  url={buttonLink}
                  label={buttonLabel}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};
