import React, { Fragment } from 'react';

import { ComponentInfoConnected } from 'views/components/ComponentInfo/ComponentInfoConnected';
import { Icon } from 'views/components/Icon/Icon';

interface IProps {
  title: string;
  text: string;
  iconName: string;
}

export const NotificationLong = (props: IProps) => {
  const {
    title, text, iconName,
  } = props;

  return (
    <Fragment>
      <ComponentInfoConnected zeplinId="NOTF01-01a" shouldShowMeta={false}/>

      <div className="Notification ModuleWrapper">
        <div className="Container">
          <div className="Notification-wrapper">
            <div className="Notification-content-top">
              <div className="Notification-icon SVG u-mT-md" aria-hidden>
                <Icon name={iconName} />
              </div>
              <div className="Notification-text">
                <h2 className="u-mB-0 u-mT-0">{title}</h2>
                <p className="u-mB-0 u-mT-0" style={{whiteSpace:"pre-line"}}>{text}</p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Fragment>
  );
};
