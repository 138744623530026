import React, { ReactNode } from 'react';
import { documentToReactComponents, Options, CommonNode } from '@contentful/rich-text-react-renderer';
import { Document, INLINES, MARKS } from '@contentful/rich-text-types';

import { isRouteURL } from 'tools/utilities/isRouteUrl';

const renderLink = (node: CommonNode, children: ReactNode): ReactNode => {
  const {
    data: {
      uri,
    },
  } = node;

  const isExternalLink = !isRouteURL(uri);
  // console.log('isExternalLink', isExternalLink, uri);

  if (isExternalLink) {
    return (
      <a className="Link u-linkClean" data-name="RichText-Link" target="_blank" rel="noopener noreferrer" href={uri}>{children}</a>
    );
  }

  return (
    <a className="Link u-linkClean" data-name="RichText-Link" href={uri}>{children}</a>
  );
};

function renderSubscript(text: ReactNode): ReactNode {
  return (
    <sub>{text}</sub>
  );
}

const DEFAULT_OPTIONS: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: renderLink,
  },
  renderMark: {
    // RichText doesn't support sub elements so make use of
    // the code elements to provide a sub option
    [MARKS.CODE]: renderSubscript,
  },
};

interface IProps {
  text: Document;
  options?: Options;
}

export const RichText = (props: IProps) => {
  const {
    text,
    options,
  } = props;

  return (
    <>
      {documentToReactComponents(text, { ...DEFAULT_OPTIONS, ...options })}
    </>
  );
};
